/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from "react";
import Logo from "../../../assets/SomoS_Co_Logo.png";
import { Link } from "react-scroll";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from '@mui/icons-material/People';
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import CommentIcon from '@mui/icons-material/Comment';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LoginIcon from '@mui/icons-material/Login';
import { motion, useScroll } from "framer-motion"
import { useNavigate } from 'react-router-dom';
import {LanguageContext} from "./LanguageContext";
import translations from "./translations";
import flagUSA from "../Assets/usa.jpg";
import flagGermany from "../Assets/germany.png";
import flagHungary from "../Assets/hungary.jpg";
import {Chip} from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
const Navbar = () => {
    const { language, setLanguage } = useContext(LanguageContext); // Using React Context to manage global state
    const t = translations[language]; // Short for "translate"
    const [showDropdown, setShowDropdown] = useState(false);
    const flagForLanguage = {
        en: flagUSA,
        de: flagGermany,
        hu: flagHungary,
    };
    const listItemTextStyle = {
        fontSize: '1rem', // Increased font size
        '& .MuiListItemText-primary': {
            fontSize: 'inherit', // Ensure the ListItemText font size is inherited
        },
    };

    // Style for ListItemIcon
    const listItemIconStyle = {
        minWidth: '46px', // Increase if necessary to align with the larger text
        '& .MuiSvgIcon-root': {
            fontSize: '1.5rem', // Larger icons
        },
    };

    // Style for ListItemButton padding
    const listItemButtonPadding = {
        padding: '12px 20px', // More padding for touch-friendly interaction
    };

    // Handler for language change
    const handleLanguageChange = (newLanguage) => {
        localStorage.setItem('preferredLanguage', newLanguage);
        window.location.reload(); // Refresh the page
    };
    useEffect(() => {
        const preferredLanguage = localStorage.getItem('preferredLanguage');
        if (preferredLanguage) {
            setLanguage(preferredLanguage);
        }
    }, [setLanguage]);
    const [navbarStyle, setNavbarStyle] = useState({
        backgroundColor: 'transparent',
        boxShadow: 'none',
        backdropFilter: 'blur(10px)',
    });
    const [openMenu, setOpenMenu] = useState(false);
    const { scrollYProgress } = useScroll();
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    const navigateToLogin = () => {
        handleCloseMenu();
        navigate('/login');
    };
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {

        scrollYProgress.onChange((latest) => {
            const opacity = Math.min(1, latest * 2); // example to calculate opacity
            const boxShadowOpacity = Math.min(0.69, latest * 1.5); // example to calculate shadow opacity
            setNavbarStyle({
                backgroundColor: `rgba(0, 0, 0, ${boxShadowOpacity})`,
                boxShadow: `0 2px 15px 0 rgba(239, 147, 3, ${opacity})`,
                backdropFilter: 'blur(4px)'
            });
        });
    }, [scrollYProgress]);
    useEffect(() => {
        const handleScroll = () => {
            // This checks if the page is scrolled more than 50 pixels
            const isScrolled = window.scrollY > 50;
            setIsScrolled(isScrolled);

            // Update navbar style based on scroll position
            if (isScrolled) {
                // Style for when the navbar is scrolled
                setNavbarStyle({
                    backgroundColor: `rgba(0, 0, 0, 0.7)`,
                    boxShadow: `0 2px 10px 0 rgba(239, 147, 3, 0.5)`,
                    backdropFilter: 'blur(10px)',
                    color: '#ffffff',
                });
            } else {
                // Style for when the navbar is at the top
                setNavbarStyle({
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    backdropFilter: 'none',
                    color: '#000000',
                });
            }
        };

        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <nav className={`navbar ${isScrolled ? "scrolled" : "transparent"}`} style={navbarStyle}>
            <div className="navbar-content">
                <div className="nav-logo-container">
                    <img src={Logo} alt="Logo"/>
                </div>
                <div className="navbar-links-container">
                    <Link to="home" smooth={true} duration={1000}>{t.home}</Link>
                    <Link to="about" smooth={true} duration={1000} offset={-100}>{t.about}</Link>
                    <Link to="process" smooth={true} duration={1000} offset={-100}>{t.work}</Link>
                    <Link to="team" smooth={true} duration={1000} offset={-100}>{t.team}</Link>
                    <Link to="testimonials" smooth={true} duration={1000} offset={-100}>{t.testimonials}</Link>
                    <Link to="contact" smooth={true} duration={1000} offset={-100}>{t.contact}</Link>
                    <div className="language-selector" onClick={() => setShowDropdown(!showDropdown)}>
                        <img src={flagForLanguage[language]} alt={`${language} flag`} className="flag-icon"/>
                        {showDropdown && (
                            <div className="language-dropdown">
                                {Object.keys(flagForLanguage).map((lang) => (
                                    <div key={lang} onClick={() => handleLanguageChange(lang)}
                                         className="dropdown-item">
                                        <img src={flagForLanguage[lang]} alt={`${lang} flag`}
                                             className={`flag-icon ${language === lang ? 'selected' : ''}`}/>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <button className="primary-button" onClick={() => navigate('/login')}>{t.signIn}</button>
                </div>
                <div className="navbar-menu-container">
                    <HiOutlineBars3 onClick={() => setOpenMenu(true)}/>
                </div>
                <Drawer
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchor="right"
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 250,
                            boxSizing: 'border-box',
                            backgroundColor: 'rgba(0,0,0,0.63)', // Black with opacity
                            backdropFilter: 'blur(10px)', // Blur effect
                            color: '#ffffff', // Text color
                            padding: '20px 0', // Add padding to top and bottom
                            //bold text
                            '& .MuiTypography-root': {
                                fontWeight: 'bold',
                            },
                        },
                    }}
                >
                    <Box
                        sx={{ width: 'auto' }}
                        role="presentation"
                        onClick={handleCloseMenu}
                        onKeyDown={handleCloseMenu}
                    >
                        <List>
                            <ListItem disablePadding>
                                <Link to="home" smooth={true} duration={1000} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><HomeIcon /></ListItemIcon>
                                        <ListItemText primary={t.home} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link to="about" smooth={true} duration={1000} offset={-100} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><InfoIcon /></ListItemIcon>
                                        <ListItemText primary={t.about} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link to="process" smooth={true} duration={1000} offset={-100} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><WorkOutlineIcon /></ListItemIcon>
                                        <ListItemText primary={t.work} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link to="team" smooth={true} duration={1000} offset={-100} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><PeopleIcon /></ListItemIcon>
                                        <ListItemText primary={t.team} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link to="testimonials" smooth={true} duration={1000} offset={-100} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><CommentIcon /></ListItemIcon>
                                        <ListItemText primary={t.testimonials} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link to="contact" smooth={true} duration={1000} offset={-100} onClick={handleCloseMenu}>
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Lighten on hover

                                        },
                                        marginBottom: '15px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><PhoneRoundedIcon /></ListItemIcon>
                                        <ListItemText primary={t.contact} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>

                        </List>
                        <Divider sx={{ borderColor: 'rgba(246,150,2,0.75)' }} />
                        <List>
                            <ListItem disablePadding>
                                <Link to="login" onClick={navigateToLogin}  >
                                    <ListItemButton sx={{
                                        ...listItemButtonPadding,
                                        '&:hover': {
                                            backgroundColor: 'rgba(23,23,23,0.81)', // Lighten on hover
                                            boxShadow: '0 2px 20px 0 #F69602',
                                        },
                                        backgroundColor: 'rgba(23,23,23,0.69)',
                                        boxShadow: '0 2px 10px 0 #F69602',
                                        borderRadius: '10px',
                                        border: '2px solid #F69602',
                                        marginBottom: '20px',
                                        marginTop: '20px',
                                        marginLeft: '50px',
                                    }}>
                                        <ListItemIcon sx={listItemIconStyle}><LoginIcon /></ListItemIcon>
                                        <ListItemText primary={t.signIn} sx={listItemTextStyle}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        </List>
                        <Divider><Chip label="Languages" size="medium" /></Divider>
                        <Box sx={{ padding: '20px 20px' }}> {/* Container for Language Selector */}
                            <Select
                                value={language}
                                onChange={(event) => handleLanguageChange(event.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                    width: '100%', // Full width of the container

                                    '.MuiSelect-select': {
                                        color: '#ffffff', // Text color
                                        '&:before': { borderBottomColor: '#ffffff' },
                                        '&:hover:not(.Mui-disabled):before': { borderBottomColor: '#ffeb3b' },
                                    },
                                }}
                            >
                                {Object.keys(flagForLanguage).map((lang) => (
                                    <MenuItem value={lang} key={lang}>
                                        <img src={flagForLanguage[lang]} alt={`${lang} flag`} style={{ width: '30px', height: '20px', marginRight: '20px', marginBottom: '-3px' }} />
                                        {lang.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                    </Box>
                </Drawer>
            </div>
        </nav>
    );
};

export default Navbar;
