import axios from 'axios';

const API_BASE_URL = 'https://my.somos-co.com/api'; // Adjust the URL based on your backend server

export const getAllUsers = () => {
    return axios.get(`${API_BASE_URL}/users`)
        .catch(error => {
            console.error("Error fetching users:", error);
            throw error; // Rethrow to handle it in the component
        });
};

// Add other API call functions here
