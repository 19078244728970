import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import translations from "./translations";

const Contact = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const [email, setEmail] = useState('');
    const [isInView, setIsInView] = useState(false);

    const handleScroll = () => {
        const section = document.getElementById("contact");
        const sectionTop = section.getBoundingClientRect().top;
        const triggerPoint = window.innerHeight / 2; // Adjust as needed

        if (sectionTop < triggerPoint) {
            setIsInView(true);
        } else {
            setIsInView(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (isInView) {
            // Start CSS gradient animation after the section is in view
            const gradientText = document.querySelector('.text-gradient');
            if (gradientText) {
                gradientText.style.animationPlayState = 'running';
            }
        }
    }, [isInView]);

    const handleInputChange = (e) => setEmail(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `mailto:peter.somos@somos-co.com?subject=Contact Request`;
    };

    const renderHeading = () => {
        if (language === 'de' || language === 'hu') {
            return (
                <>
                    {t.contact_heading1}
                    {t.contact_heading1_2}
                    <span className={`text-gradient ${isInView ? 'text-animate' : ''}`}>
                        {t.contact_keyword}
                    </span>?
                </>
            );
        } else {
            return (
                <>
                    {t.contact_heading1}
                    <span className={`text-gradient ${isInView ? 'text-animate' : ''}`}>
                        {t.contact_keyword}
                    </span>
                    {t.contact_heading1_2}
                </>
            );
        }
    };

    return (
        <div className="contact-page-wrapper" id="contact">
            <h1 className="primary-heading no-hover-effect heading-wide">
                {renderHeading()}
            </h1>
            <br/><br/><br/><br/>
            <h1 className="primary-heading">{t.contact_heading2}</h1>
            <div className="contact-form-container">
                <input
                    type="email"
                    placeholder={t.contact_email}
                    value={email}
                    onChange={handleInputChange}
                />
                <button className="submit-button" onClick={handleSubmit}>
                    <div className="svg-wrapper-1">
                        <div className="svg-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill="currentColor"
                                      d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                            </svg>
                        </div>
                    </div>
                    <span>{t.contact_button}</span>
                </button>
            </div>
        </div>
    );
};

export default Contact;
