// Loader.js

function Loader() {
    return (

            <div className="loader"></div>


    );
}

export default Loader;
