import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, TextField, IconButton, DialogContentText, AppBar, Toolbar} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import './DataTable.css';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    addRow,
    deleteRow,
    updateRow,
    executeDBWritebacks,
    executeDBMultipleWritebacks,
    executeUSPDynamicPivot,
    setDimensionSelection, executeQuery
} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import ViewColumnIcon from '@mui/icons-material/ViewColumn'; // For 'fixed' layout
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from "@mui/icons-material/Search";
import DimensionDropdown from "./DimensionDropdown";
const Resizer = ({ columnIndex, onResize }) => {
    let currentWidth;

    const handleMouseDown = (event) => {
        event.preventDefault(); // Prevent default drag behavior

        const startWidth = event.target.parentElement.offsetWidth;
        const startX = event.clientX;

        const handleMouseMove = (moveEvent) => {
            // Calculate the new width
            currentWidth = startWidth + (moveEvent.clientX - startX);

            // Enforce a minimum width of 150px
            if (currentWidth < 150) {
                currentWidth = 150;
            }

            event.target.parentElement.style.width = `${currentWidth}px`;
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            if (typeof onResize === 'function') {
                onResize(columnIndex, `${currentWidth}px`);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return <div className="resizer" onMouseDown={handleMouseDown} />;
};
const DataTable = ({ initialData, columns, selectedTableName, primaryKeyColumnName }) => {
    const [rows, setRows] = useState(initialData);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedCells, setEditedCells] = useState(new Set());
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newRowData, setNewRowData] = useState({}); // For the add dialog
    const dispatch = useDispatch();
    const [tableLayout, setTableLayout] = useState('fixed');
    const [filterQuery, setFilterQuery] = useState('');
    const [visibleColumns, setVisibleColumns] = useState(new Set(columns.map(column => column.field)));
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);
    const dimensionSelections = useSelector(state => state.dimensions.dimensionSelections);
    const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
    const [openDeleteProcedureDialog, setOpenDeleteProcedureDialog] = useState(false);
    const [columnIdentifier, setColumnIdentifier] = useState('H1_4');
    const isWritebackTable = selectedTableName === 'writeback';
    const [dynamicData, setDynamicData] = useState([]); // State to store the dynamic data
    const [dynamicColumns, setDynamicColumns] = useState(columns);
    const [isSimpleSelectActive, setIsSimpleSelectActive] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [writebackEditMode, setWritebackEditMode] = useState(false);
    const [writebackChanges, setWritebackChanges] = useState({});
    const [currentParameters, setCurrentParameters] = useState({
        STATIC_COLUMN: columnIdentifier,
        PIVOT_COLUMN: 'H2_3',
        VALUE_COLUMN: 'D1_1',
        TABLE: 'V_Ralph_WB',
        AGGREGATE: 'sum',
        regionID: '1',
        marketSegmentID: '1',
        SAPGroupID: '1',
        fkID: '1',
        FILTER: "H1_3 LIKE '%|' AND H1_2 LIKE '%%' AND H1_1 LIKE '%%'",
        TotalCol: '1',
        TotalRow: '1',
        TotalRowTarget: '1'
    });

    const handleToggleWritebackEditMode = () => {
        setWritebackEditMode(!writebackEditMode);
        setWritebackChanges({});
    };

    const handleWritebackChange = (rowIndex, columnId, value) => {
        setWritebackChanges(prev => ({
            ...prev,
            [rowIndex]: {
                ...prev[rowIndex],
                [columnId]: value
            }
        }));
    };

    const handleDBUpdate = async () => {
        for (const [rowIndex, changes] of Object.entries(writebackChanges)) {
            const row = dynamicData[rowIndex];
            if (!row) continue;

            const dimIDs = parseDimIDs(row['H1_4']); // Get dimensional IDs
            for (const column of dynamicColumns) {
                const date = column.headerName.split('@')[1]; // Extract date after '@'

                // Extract old and new values and split them
                const oldValues = row[column.field].split(' - ');
                const newValues = changes[column.field] ? changes[column.field].split(' - ') : oldValues.slice();

                // Determine which metrics have changed
                const metricsChanged = newValues.map((newValue, index) => {
                    return newValue !== oldValues[index] ? index + 1 : null;
                }).filter(value => value !== null);

                // Iterate over changed metrics and update them
                for (const metricIndex of metricsChanged) {
                    const metricName = `metric0${metricIndex}`; // Assuming metrics are named 'metric01', 'metric02', etc.
                    const newValue = newValues[metricIndex - 1];
                    const newIntegerValue = Math.floor(newValue); // Convert to integer

                    const updateParams = [
                        ...dimIDs.map(id => `'${id}'`),
                        `'${date}'`,
                        `'${metricName}'`,
                        newIntegerValue,
                        0  // Placeholder for any additional parameters
                    ].join(', ');

                    const sqlQuery = `EXEC [dbo].[DBUpdate] ${updateParams}`;
                    console.log("Executing update with query:", sqlQuery);

                    try {
                        const response = await dispatch(executeQuery({ query: sqlQuery, parameters: {} })).unwrap();
                        console.log("DBUpdate executed successfully for metric:", metricName, "in row:", rowIndex, response);
                    } catch (error) {
                        console.error("Failed to execute DBUpdate for metric:", metricName, "with error:", error);
                    }
                }
            }
        }
        setWritebackEditMode(false); // Exit edit mode after updates
        setWritebackChanges({}); // Clear changes after update
    };







    // Function to open the Add Product dialog
    const handleOpenAddProductDialog = () => {
        const initialData = dynamicColumns.reduce((acc, column) => ({
            ...acc,
            [column.field]: ''  // Initialize all fields with an empty string
        }), {});
        setNewRowData(initialData);
        setOpenAddProductDialog(true);
    };


    const handleAddProduct = () => {
        const values = dynamicColumns.map(column => {
            const value = newRowData[column.field] || 'default';  // Ensure no undefined values; replace with 'default' or another placeholder
            return `'${value}'`;  // Properly format the value for SQL query
        }).join(', ');
        const sqlQuery = `EXEC [dbo].[DBInsert] ${values}`;
        dispatch(executeQuery({ query: sqlQuery, parameters: {} }))
            .unwrap()
            .then(response => {
                console.log("Product added successfully:", response);
                setOpenAddProductDialog(false);  // Close the dialog
                setNewRowData({});  // Reset the newRowData state
            })
            .catch(error => {
                console.error("Failed to add product:", error);
            });
    };



    const handleOpenDeleteProcedureDialog = (rowIndex) => {
        console.log("Opening delete dialog for row:", rowIndex); // Ensure rowIndex is logged correctly
        setDeleteTarget(rowIndex);
        setOpenDeleteProcedureDialog(true);
    };

    const handleCloseDeleteProcedureDialog = (confirmDelete) => {
        console.log("Confirm delete:", confirmDelete, "for row index:", deleteTarget); // Log to check the index on confirm
        if (confirmDelete && deleteTarget !== null) {
            handleDBDelete(deleteTarget).then(r => console.log('DBDelete executed:', r));
        }
        setOpenDeleteProcedureDialog(false);
        setDeleteTarget(null);
    };



    // Function to close the Add Product dialog, optionally passing a flag to indicate if a product was added
    const handleCloseAddProductDialog = () => {
        setOpenAddProductDialog(false);
        setNewRowData({});
    };

    useEffect(() => {
        console.log("Columns at init or update:", columns);
        setVisibleColumns(new Set(columns.map(column => column.field)));
    }, [columns]);

    const normalizeString = (str) => {
        // Removes all delimiters and special characters except spaces
        return str.replace(/[#@|]+/g, ' ').toLowerCase();
    };

    const filteredRows = useMemo(() => {
        if (!filterQuery) return isWritebackTable ? dynamicData : rows;

        const lowerCaseQuery = filterQuery.toLowerCase();
        const dataToFilter = isWritebackTable ? dynamicData : rows;
        const currentColumns = isWritebackTable ? dynamicColumns : columns;

        return dataToFilter.filter(row => {
            return currentColumns.some(column => {
                if (visibleColumns.has(column.field)) {
                    const rowData = String(row[column.field]);
                    const normalizedData = normalizeString(rowData);

                    console.log(`Filtering: checking if ${normalizedData} includes ${lowerCaseQuery}`);
                    return normalizedData.includes(lowerCaseQuery);
                }
                return false;
            });
        });
    }, [rows, dynamicData, filterQuery, isWritebackTable, visibleColumns, dynamicColumns, columns]);


    const fetchData = (sqlQuery, parameters = {}) => {
        console.log("Executing query:", sqlQuery, "with parameters:", parameters); // Add this line to debug
        dispatch(executeQuery({ query: sqlQuery, parameters }))
            .unwrap()
            .then(response => {
                console.log("Database response:", response);
                if (isWritebackTable) {
                    const newColumns = response.length > 0 ? Object.keys(response[0]).map(key => ({
                        field: key,
                        headerName: key,
                        width: 150
                    })) : [];
                    setDynamicData(response);
                    setDynamicColumns(newColumns);
                }
            })
            .catch(error => {
                console.error("Failed to fetch data:", error);
            });
    };

    const handleDimensionChange = (tableName, newDimID) => {
        // Use the previous state to create a new state
        setCurrentParameters(prevParams => {
            setDynamicData([]);
            const newParams = { ...prevParams };
            switch(tableName) {
                case 'dim01': // Corresponds to REGION
                    newParams.regionID = newDimID;
                    break;
                case 'dim02': // Corresponds to MARKET SEGMENT
                    newParams.marketSegmentID = newDimID;
                    break;
                case 'dim03': // Corresponds to CUSTOMER GROUP
                    newParams.SAPGroupID = newDimID;
                    break;
                case 'dim04': // Corresponds to CUSTOMER
                    newParams.fkID = newDimID;
                    break;
                case 'dim10': // Corresponds to CURRENCY
                    newParams.currencyID = newDimID;
                    break;
                // Continue for other cases as per your table mappings
            }
            console.log(`Updated parameters for ${tableName}:`, newParams);
            return newParams;
        });
    };
    const handleProductDimensionChange = (fieldName, newDimID) => {
        setNewRowData(prev => ({
            ...prev,
            [fieldName]: newDimID  // Update the specific field with the new dimension ID
        }));
    };
    //fetchdatawith new parameters in a callback function

    // Wrap this function with useCallback to ensure it doesn't change unless necessary
    const fetchDataWithNewParameters = useCallback(async (params) => {
        console.log('Fetching data with parameters:', params);
        try {
            const response = await dispatch(executeUSPDynamicPivot(params)).unwrap();
            console.log('Data fetched successfully:', response);
            setDynamicData(response);
            // Additional logic to handle response
        } catch (error) {
            console.error("Failed to fetch data with new parameters:", error);
        }
    }, [dispatch]);  // Include dispatch in dependencies if it could change, though it usually doesn't in most apps


    useEffect(() => {
        if (isWritebackTable) {
            console.log('Fetching data due to refresh trigger or parameter change');
            fetchDataWithNewParameters(currentParameters).then(r => console.log('Data fetched:', r));
        }
    }, [refreshTrigger, currentParameters, isWritebackTable, fetchDataWithNewParameters]);  // Correctly listed as a dependency




    const handleExecuteUSPDynamicPivot = () => {
        setColumnIdentifier('H1_4');
        const parameters = {
            STATIC_COLUMN: columnIdentifier,
            PIVOT_COLUMN: 'H2_3',
            VALUE_COLUMN: 'D1_1',
            TABLE: 'V_Ralph_WB',
            AGGREGATE: 'sum',
            regionID: '1',
            marketSegmentID: '1',
            SAPGroupID: '1',
            fkID: '1',
            FILTER: "H1_3 LIKE '%|' AND H1_2 LIKE '%%' AND H1_1 LIKE '%%'",
            TotalCol: '1',
            TotalRow: '1',
            TotalRowTarget: '1'
        };

        setIsSimpleSelectActive(false);
        fetchData("exec [dbo].[USP_DYNAMIC_PIVOT]", parameters);
    };

    const handleSimpleSelect = () => {
        setIsSimpleSelectActive(true);
        fetchData("SELECT * FROM V_Ralph_WB");

    };



    useEffect(() => {
        if (selectedTableName === 'writeback') {
            setIsSimpleSelectActive(false);
            setColumnIdentifier('H1_4');
            const fetchUSPDynamicPivotData = async () => {
                const parametersWithVolume = {
                    STATIC_COLUMN: columnIdentifier,
                    PIVOT_COLUMN: 'H2_3',
                    VALUE_COLUMN: 'D1_1',
                    TABLE: 'V_Ralph_WB',
                    AGGREGATE: 'sum',
                    regionID: '1',
                    marketSegmentID: '1',
                    SAPGroupID: '1',
                    fkID: '1',
                    FILTER: "H1_3 LIKE '%|' AND H1_2 LIKE '%%' AND H1_1 LIKE '%%'",
                    TotalCol: '1',
                    TotalRow: '1',
                    TotalRowTarget: '1'
                };

                const parametersWithPrice = {
                    STATIC_COLUMN: columnIdentifier,
                    PIVOT_COLUMN: 'H2_3',
                    VALUE_COLUMN: 'D1_2',
                    TABLE: 'V_Ralph_WB',
                    AGGREGATE: 'sum',
                    regionID: '1',
                    marketSegmentID: '1',
                    SAPGroupID: '1',
                    fkID: '1',
                    FILTER: "H1_3 LIKE '%|' AND H1_2 LIKE '%%' AND H1_1 LIKE '%%'",
                    TotalCol: '1',
                    TotalRow: '1',
                    TotalRowTarget: '1'
                };
                try {
                    // Fetch data for D1_1
                    const responseD1_1 = await dispatch(executeUSPDynamicPivot(parametersWithVolume)).unwrap();

                    // Fetch data for D1_2
                    const responseD1_2 = await dispatch(executeUSPDynamicPivot(parametersWithPrice)).unwrap();

                    // Set new columns based on the first response
                    const newColumns = responseD1_1.length > 0 ? Object.keys(responseD1_1[0]).map(key => ({
                        field: key,
                        headerName: key,
                        width: 150 // or other logic for setting width
                    })) : [];
                    setDynamicColumns(newColumns);

                    // Update visible columns based on dynamic columns
                    if (newColumns.length > 0) {
                        setVisibleColumns(new Set(newColumns.map(col => col.field)));
                    }

                    // Combine both responses
                    const combinedResponse = responseD1_1.map((item, index) => {
                        // Combine the D1_1 and D1_2 values for each key except for 'H1_4' and 'TOTAL'
                        const combinedItem = {...item};
                        Object.keys(item).forEach(key => {
                            if (key !== columnIdentifier && key !== "TOTAL") {
                                combinedItem[key] = `${item[key] || ''} - ${responseD1_2[index][key] || ''}`;
                            }
                        });
                        return combinedItem;
                    });

                    // Set the combined data to your state
                    setDynamicData(combinedResponse);

                    console.log('Combined response:', combinedResponse);

                } catch (error) {
                    console.error("Failed to fetch dynamic pivot data:", error);
                }
            };

            fetchUSPDynamicPivotData();
        } else {
            // If not 'writeback', reset to initial data or handle accordingly
            setRows(initialData);
            setDynamicColumns(columns); // Reset dynamic columns to initial columns
            setVisibleColumns(new Set(columns.map(col => col.field))); // Reset visible columns to match initial columns
        }
    }, [selectedTableName, dispatch, initialData, columns, columnIdentifier]);

    useEffect(() => {
        console.log("Table selection changed to:", selectedTableName);
        if (selectedTableName === 'writeback') {
            console.log("Writeback table selected, dynamic columns:", dynamicColumns);
        } else {
            console.log("Non-writeback table selected, resetting columns and data");
            setDynamicData([]);
            setDynamicColumns(columns);
        }
    }, [selectedTableName, columns, dynamicColumns]);


    const handleToggleColumnVisibility = (columnField) => {
        setVisibleColumns(prevVisibleColumns => {
            const updatedVisibleColumns = new Set(prevVisibleColumns);
            if (updatedVisibleColumns.has(columnField)) {
                updatedVisibleColumns.delete(columnField);
            } else {
                updatedVisibleColumns.add(columnField);
            }
            return updatedVisibleColumns;
        });
    };

    const handleSelectAllColumns = () => {
        setVisibleColumns(prevVisibleColumns => {
            if (prevVisibleColumns.size === columns.length) {
                return new Set();
            } else {
                return new Set(columns.map(column => column.field));
            }
        });
    };


    const [setColumnWidths] = useState(
        columns.reduce((widths, column, index) => {
            // Set minimum width to 150px if not specified
            widths[index] = column.width ? `${column.width}px` : '150px';
            return widths;
        }, {})
    );

    const handleColumnResize = (index, newWidth) => {
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [index]: typeof newWidth === 'number' ? `${newWidth}px` : newWidth,
        }));
    };
    // Function to enter edit mode for all rows

    useEffect(() => {
        if(!isWritebackTable){
            setRows(initialData);
        }
    }, [initialData, isWritebackTable]);
    const handleEdit = () => {
        setIsEditMode(true);
    };

    // Function to save changes for all rows
    const handleSave = () => {
        if (!isWritebackTable) {
            console.log(`Saving edited cells:`, Array.from(editedCells));
            editedCells.forEach(cellKey => {
                const [id, fieldName] = cellKey.split("-");
                const row = rows.find(row => row[primaryKeyColumnName] === Number(id));
                if (row) {
                    dispatch(updateRow({tableName: selectedTableName, id: row[primaryKeyColumnName], changes: {[fieldName]: row[fieldName]}}));
                }
            });
        } else {
            // Implement the procedure call for writeback scenario
            // Example: dispatch(executeWritebackProcedure(editedCells));
        }
        setEditedCells(new Set());
        setIsEditMode(false);
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);

    const handleCloseDeleteDialog = (confirmDelete) => {
        if (confirmDelete) {
            if (!isWritebackTable) {
                selectedRows.forEach(id => {
                    dispatch(deleteRow({tableName: selectedTableName, id}));
                });
            } else {
                // Implement the procedure call for writeback scenario
                // Example: dispatch(executeWritebackDelete(selectedRows));
            }
            setSelectedRows(new Set());
        }
        setOpenDeleteDialog(false);
    };

// Function to handle the add row dialog opening
    const handleOpenAddDialog = () => setOpenAddDialog(true);

// Function to close the add row dialog and optionally add the new row
    const handleCloseAddDialog = (add) => {
        if (add) {
            if (!isWritebackTable) {
                const newRow = { ...newRowData };
                dispatch(addRow({tableName: selectedTableName, newRow}));
            } else {
                // Implement the procedure call for writeback scenario
                // Example: dispatch(executeWritebackAdd(newRowData));
            }
        }
        setOpenAddDialog(false);
        setNewRowData({});
    };

    // Function to cancel changes and revert to initial data
    const handleCancel = () => {
        setRows(initialData);
        setIsEditMode(false);
    };

    // Function to handle changes to each input field
    const handleChange = (e, id, fieldName) => {
        console.log(`Changing value for row: ${id}, field: ${fieldName}, newValue: ${e.target.value}`);
        const newRows = rows.map((row) => {
            if (row[primaryKeyColumnName] === id) {
                setEditedCells(new Set([...editedCells, `${id}-${fieldName}`]));
                return { ...row, [fieldName]: e.target.value, _isEdited: true };
            }
            return row;
        });
        setRows(newRows);
    };

    const handleRowSelection = (e, id) => {
        const newSelectedRows = new Set(selectedRows);
        if (newSelectedRows.has(id)) {
            newSelectedRows.delete(id);
        } else {
            newSelectedRows.add(id);
        }
        setSelectedRows(newSelectedRows);
    };
    console.log("Rows data: ", rows);
    console.log("Primary Key Column Name: ", primaryKeyColumnName);


    useEffect(() => {
        if(isWritebackTable){
            console.log("Dimension selections:", dimensionSelections);
        }
    }, [dimensionSelections, isWritebackTable]);

    const formatTextWithNewLines = (text) => {
        // Check if '@' exists in the string to prevent errors
        const atIndex = text.indexOf('@');
        if (atIndex === -1) return null; // Return null if '@' is not found

        // Extract everything after the first '@'
        let partsAfterAt = text.substring(atIndex + 1);

        // Special handling to clean up the text for display
        // Remove everything after the last meaningful content which is 'TOTAL' or any text without '@' or '|'
        partsAfterAt = partsAfterAt.split('@')[0]; // Taking the part before any subsequent '@'
        partsAfterAt = partsAfterAt.split('|').filter(part => !part.includes('@')); // Removing any parts with '@'

        const displayParts = partsAfterAt.map(part => {
            const [contentBeforeHash] = part.split('#');
            return contentBeforeHash.trim(); // Trim to remove any leading/trailing whitespace.
        });

        console.log("Display parts for UI:", displayParts); // Debugging line

        // Return a React fragment containing each part separated by a line break
        return displayParts.map((content, index) => (
            <React.Fragment key={index}>
                {content}
                {(index < displayParts.length - 1) && <br />}
            </React.Fragment>
        ));
    };


    const formatColumnHeader = (headerText) => {
        if (!headerText) return null; // Return null if headerText is undefined or null

        // Remove everything after the '@' symbol, including the '@' itself
        const cleanedHeaderText = headerText.split('@')[0];

        // Split the remaining header text by '|', and return them separated by <br/> for display
        return cleanedHeaderText.split('|').map((part, index, array) => (
            <React.Fragment key={index}>
                {part}
                {index < array.length - 1 && <br />}
            </React.Fragment>
        ));
    };





    const handleDBInsert = () => {
        const sqlQuery = "exec [dbo].[DBInsert] '1', '1', '4', '1', 1, 1, 1, 1, 1, 1, 0";
        dispatch(executeQuery({ query: sqlQuery, parameters: {} }))  // Ensure to pass an empty object for parameters
            .unwrap()
            .then(response => {
                console.log("DBInsert executed successfully:", response);
            })
            .catch(error => {
                console.error("Failed to execute DBInsert:", error);
            });
    };
    const parseDimIDs = (dataString) => {
        console.log("Parsing DIM IDs from:", dataString);
        const regex = /dim(\d+)ID:(\d+)/g;
        let match;
        const ids = [];
        while (match = regex.exec(dataString)) {
            ids.push({dim: parseInt(match[1], 10), id: match[2]}); // Capture both dimension number and ID
        }

        // Sort by dimension number
        ids.sort((a, b) => a.dim - b.dim);

        // Map sorted array to only ID values
        const sortedIDs = ids.map(item => item.id);
        console.log("Extracted and sorted IDs:", sortedIDs);
        return sortedIDs;
    };


    const handleDBDelete = async (rowIndex) => {
        console.log("Attempting to delete row at index:", rowIndex); // Confirm index is received
        if (rowIndex == null || !dynamicData[rowIndex]) {
            console.error("Row to delete not found or invalid row index");
            return;
        }

        const dataString = dynamicData[rowIndex]['H1_4'];
        if (!dataString) {
            console.error("Data string for deletion not found.");
            return;
        }

        const dimIDs = parseDimIDs(dataString);
        if (dimIDs.length === 0) {
            console.error("No DIM IDs found in data string:", dataString);
            return;
        }

        const queryParameters = dimIDs.map(id => `'${id}'`).join(', ');
        const sqlQuery = `EXEC [dbo].[DBDelete] ${queryParameters}`;
        console.log("Executing delete with query:", sqlQuery);

        try {
            const response = await dispatch(executeQuery({ query: sqlQuery, parameters: {} })).unwrap();
            console.log("DBDelete executed successfully for row:", rowIndex, response);
            setRefreshTrigger(old => old + 1);
        } catch (error) {
            console.error("Failed to execute DBDelete:", error);
        }
    };







    const extractValueAndCheckReadOnly = (cellValue) => {
        const readOnlyMarker = "@isReadOnly:0";
        // Convert cellValue to string in case it's not
        const valueStr = String(cellValue);
        if (valueStr) {
            const isReadOnly = valueStr.includes(readOnlyMarker);
            const value = valueStr.replace(readOnlyMarker, "").trim();
            return { value, isReadOnly };
        }
        // If cellValue was null or undefined, return it as an empty string and assume it's not read-only
        return { value: '', isReadOnly: false };
    };

    const parseAndSetDropdownValues = useCallback((cellContent) => {
        const parts = cellContent.split('|');
        const dimensions = parts.flatMap(part => {
            const [content, dimInfo] = part.split('#');
            if (dimInfo) {
                const match = dimInfo.match(/(dim\d+)ID:(\d+)/);
                if (match) {
                    const [, dimName, dimValue] = match;
                    return { dimName, dimValue };
                }
            }
            return [];
        }).filter(Boolean);

        console.log("Parsed dimensions for dropdowns:", dimensions); // Debugging line

        dimensions.forEach(({ dimName, dimValue }) => {
            dispatch(setDimensionSelection({ tableName: dimName, selection: dimValue }));
        });
    }, [dispatch]);

    useEffect(() => {
        if (isWritebackTable && dynamicData.length > 0) {
            const firstCellContent = dynamicData[0][columnIdentifier];
            parseAndSetDropdownValues(firstCellContent);
        }
    }, [isWritebackTable, dynamicData, parseAndSetDropdownValues, columnIdentifier]);

    console.log("Dynamic Columns:", dynamicColumns);
    console.log("Dynamic Data:", dynamicData);
    console.log("Visible Columns:", Array.from(visibleColumns));
    return (
        <Box sx={{width: '100%', maxWidth: 'calc(100% - 100px)', ml: '50px'}}>
            <div className="actions">
                {
                    !isWritebackTable && (
                        <>
                            <Button
                                startIcon={<AddBoxIcon/>}
                                onClick={handleOpenAddDialog}
                                variant="contained"
                                color="primary"
                            >
                                Add Row
                            </Button>


                            {isEditMode ? (
                                <>
                                    <IconButton onClick={handleSave} color="primary" size="large">
                                        <SaveIcon/>
                                    </IconButton>
                                    <IconButton onClick={handleCancel} color="secondary" size="large">
                                        <CancelIcon/>
                                    </IconButton>
                                </>
                            ) : (
                                <Button
                                    startIcon={<EditIcon/>}
                                    onClick={handleEdit}
                                    variant="contained"
                                    color="primary"
                                >
                                    Edit Mode
                                </Button>

                            )}

                            <Button
                                startIcon={<DeleteIcon/>}
                                onClick={handleOpenDeleteDialog}
                                variant="contained"
                                color="error"
                                disabled={selectedRows.size === 0}
                            >
                                Delete Row(s)
                            </Button>
                        </>
                    )
                }
                <IconButton onClick={() => setTableLayout('fixed')} color="primary" size="large">
                    <ViewColumnIcon/>
                </IconButton>
                <IconButton onClick={() => setTableLayout('auto')} color="primary" size="large">
                    <AutoFixHighIcon/>
                </IconButton>
                <TextField
                    label="Filter Rows"
                    variant="outlined"
                    size="small"
                    value={filterQuery}
                    onChange={(e) => setFilterQuery(e.target.value)}
                    onFocus={() => setSearchFocus(true)}
                    onBlur={() => setSearchFocus(false)}
                    style={{
                        marginRight: '10px',
                        marginTop: "5px",
                        border: searchFocus ? '1px solid #ef9303' : '1px solid white',
                        borderRadius: '5px',
                        backgroundColor: 'transparent'
                    }}
                    InputLabelProps={{
                        style: { color: searchFocus ? 'transparent' : 'white' },
                    }}
                    InputProps={{
                        style: { color: 'white' },
                    }}
                />
                <Button
                    startIcon={<SearchIcon/>}
                    onClick={() => setOpenFilterDialog(true)}
                    variant="contained"
                    sx={{ ml: 1 }}
                >
                    Filter Columns
                </Button>
                <>
                    {writebackEditMode ? (
                        <Button
                            startIcon={<SaveIcon />}
                            onClick={handleDBUpdate}
                            variant="contained"
                            color="primary"
                        >
                            Save Changes
                        </Button>
                    ) : (
                        <Button
                            startIcon={<EditIcon />}
                            onClick={handleToggleWritebackEditMode}
                            variant="contained"
                            color="primary"
                        >
                            Edit Writeback Data
                        </Button>
                    )}
                </>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDBInsert}
                >
                    Execute DBInsert
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExecuteUSPDynamicPivot} // Correct way to reference the function
                >
                    Test USPDynamicPivot
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSimpleSelect} // Correct way to reference the function
                >
                    Test SimpleSelect
                </Button>
            </div>
            {isWritebackTable && (
                <AppBar position="static" sx={{ backgroundColor: 'rgba(41,41,41,0.51)', width:"80%", }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap', // Allow the toolbar to wrap content on small screens
                            '& > *': { mb: 1 }, // Add bottom margin to all direct children for spacing when wrapped
                        }}
                    >
                        <Box sx={{ display: 'flex', flexGrow: 1, gap: 2, flexBasis: 'auto' }}>
                            <DimensionDropdown label="REGION" tableName="dim01" onDimensionChange={handleDimensionChange} />
                            <DimensionDropdown label="MARKET SEGMENT" tableName="dim02" onDimensionChange={handleDimensionChange} />
                            <DimensionDropdown label="CUSTOMER GROUP" tableName="dim03" onDimensionChange={handleDimensionChange} />
                            <DimensionDropdown label="CUSTOMER" tableName="dim04" onDimensionChange={handleDimensionChange} />
                            <DimensionDropdown label="CURRENCY" tableName="dim10" onDimensionChange={handleDimensionChange} />
                        </Box>
                        <Button
                            startIcon={<AddBoxIcon />}
                            onClick={handleOpenAddProductDialog}
                            variant="contained"
                            color="primary"
                            sx={{
                                flexGrow: 0,
                                whiteSpace: 'nowrap', // Prevent the text inside the button from wrapping
                                ml: 2, // Add left margin when not wrapped
                                mt: { xs: 2, sm: 0 }, // Add top margin only on extra small devices
                            }}
                        >
                            ADD PRODUCT
                        </Button>
                    </Toolbar>
                </AppBar>
            )}

            <div className="data-table-container">
                <table className="data-table" style={{tableLayout: tableLayout}}>
                    <thead>
                    <tr>
                        <th className="checkbox-column">
                            <Checkbox
                                style={{color: '#fff'}}
                                indeterminate={selectedRows.size > 0 && selectedRows.size < rows.length}
                                checked={rows.length > 0 && selectedRows.size === rows.length}
                                onChange={(e) => {
                                    const newSelectedRows = new Set();
                                    if (e.target.checked) {
                                        rows.forEach(row => newSelectedRows.add(row[primaryKeyColumnName]));
                                    }
                                    setSelectedRows(newSelectedRows);
                                }}
                            />
                        </th>
                        {(isWritebackTable ? dynamicColumns : columns)
                            .filter(column => visibleColumns.has(column.field))
                            .map((column, index) => (
                                <th key={column.field || index} style={{ minWidth: column.width }}>
                                    {isWritebackTable ? formatColumnHeader(column.headerName) : column.name}
                                    <Resizer columnIndex={index} onResize={handleColumnResize}/>
                                </th>
                            ))
                        }
                    </tr>
                    </thead>

                    <tbody>
                    {(isWritebackTable ? dynamicData : filteredRows).map((row, rowIndex) => {
                        const primaryKeyValue = row[primaryKeyColumnName];

                        return (
                            <tr key={primaryKeyValue || rowIndex}>
                                <td className="checkbox-column">
                                    <Checkbox
                                        style={{color: '#fff'}}
                                        checked={selectedRows.has(primaryKeyValue)}
                                        onChange={(e) => handleRowSelection(e, primaryKeyValue)}
                                    />
                                </td>
                                {(isWritebackTable ? dynamicColumns : columns)
                                    .filter(column => visibleColumns.has(column.field))
                                    .map((column, colIndex) => {
                                        const cellKey = `${primaryKeyValue}-${column.field}`;
                                        const isCellEdited = editedCells.has(cellKey);
                                        const {
                                            value: cellValue,
                                            isReadOnly
                                        } = extractValueAndCheckReadOnly(row[column.field]);

                                        // Declare variable for rendered content
                                        let renderedContent;

                                        if (column.field === columnIdentifier) {
                                            renderedContent = formatTextWithNewLines(cellValue);
                                        } else if (isWritebackTable) {
                                            // Adjust this logic to ensure null values are displayed correctly
                                            const values = cellValue.split(' - ');
                                            const valueTop = values[0] || 'null'; // Set to 'null' if the first value is empty
                                            const valueBottom = values[1] || 'null'; // Set to 'null' if the second value is empty

                                            renderedContent = (
                                                <>
                                                    <div>{valueTop}</div>
                                                    {valueTop !== 'null' || valueBottom !== 'null' ? (
                                                        <Box component="hr" sx={{
                                                            width: '100%',
                                                            my: '5px',
                                                            border: 'none',
                                                            borderTop: '2px solid #fff'
                                                        }}/>
                                                    ) : null}
                                                    <div>{valueBottom}</div>
                                                </>
                                            );
                                        } else {
                                            renderedContent = cellValue;
                                        }
                                        if (isWritebackTable) {
                                            // Render cell with writeback-specific editing controls
                                            return (
                                                <td key={cellKey}>
                                                    {writebackEditMode ? (
                                                        <TextField
                                                            value={writebackChanges[rowIndex]?.[column.field] || row[column.field]}
                                                            onChange={(e) => handleWritebackChange(rowIndex, column.field, e.target.value)}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        renderedContent
                                                    )}
                                                </td>
                                            );
                                        } else {
                                            // Render cell with general editing controls
                                            const isEditable = !isReadOnly && isEditMode && visibleColumns.has(column.field);
                                            return (
                                                <td key={cellKey} className={isCellEdited ? 'edited-cell' : ''}>
                                                    {isEditable ? (
                                                        <TextField
                                                            value={cellValue}
                                                            onChange={(e) => handleChange(e, primaryKeyValue, column.field)}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        renderedContent
                                                    )}
                                                </td>
                                            );
                                        }
                                    })}
                                {isWritebackTable && (
                                    <td>
                                        <IconButton onClick={() => handleOpenDeleteProcedureDialog(rowIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                    </tbody>

                </table>
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={() => handleCloseDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{color: '#fff', fontSize: '1.1rem'}}>
                        Do you really want to delete the selected row(s)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleCloseDeleteDialog(false)}
                        sx={{color: 'red', fontSize: '1rem'}}>
                        No
                    </Button>
                    <Button
                        onClick={() => handleCloseDeleteDialog(true)}
                        sx={{color: '#fff', fontSize: '1rem'}} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAddDialog} onClose={() => handleCloseAddDialog(false)}>
                <DialogTitle sx={{color: '#fff', fontSize: '1.2rem'}}>Add New Row</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: '#fff', fontSize: '1rem'}}>
                        Enter the data for the new row.
                    </DialogContentText>
                    {columns.map(column => (
                        <TextField
                            key={column.field}
                            autoFocus
                            margin="dense"
                            id={column.field}
                            label={column.name}
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={e => setNewRowData({...newRowData, [column.field]: e.target.value})}
                            InputLabelProps={{
                                style: {color: '#fff'}, // styles for label
                            }}
                            inputProps={{
                                style: {color: '#fff'}, // styles for input text
                            }}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleCloseAddDialog(false)}
                        sx={{color: '#fff', fontSize: '1rem'}}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleCloseAddDialog(true)}
                        sx={{color: '#fff', fontSize: '1rem'}}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openFilterDialog} onClose={() => setOpenFilterDialog(false)}>
                <DialogTitle>Filter Columns</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select columns you want to display.
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleColumns.size === columns.length}
                                indeterminate={visibleColumns.size > 0 && visibleColumns.size < columns.length}
                                onChange={handleSelectAllColumns}
                                style={{ color: 'white' }}
                            />
                        }
                        label="Select All"
                    />
                    {(isWritebackTable ? dynamicColumns : columns).map((column) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={visibleColumns.has(column.field)}
                                    onChange={() => handleToggleColumnVisibility(column.field)}
                                    style={{ color: 'white' }}
                                />
                            }
                            label={isWritebackTable ? column.headerName : column.name}
                            key={column.field}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: '#fff', fontSize: '1rem'}} onClick={() => setOpenFilterDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAddProductDialog} onClose={() => setOpenAddProductDialog(false)}>
                <DialogTitle>Add New Product</DialogTitle>
                <DialogContent>
                    {dynamicColumns.map(column => {
                        const isDimension = column.type === 'dimension'; // Assuming you have a way to determine this
                        return isDimension ? (
                            <DimensionDropdown
                                key={column.field}
                                label={column.headerName}
                                tableName={column.dimensionTableName} // Assume this maps to something like 'dim01', 'dim02', etc.
                                onDimensionChange={(tableName, newDimID) => handleProductDimensionChange(column.field, newDimID)}
                            />
                        ) : (
                            <TextField
                                key={column.field}
                                label={column.headerName || column.name}
                                value={newRowData[column.field] || ''}
                                onChange={e => setNewRowData({...newRowData, [column.field]: e.target.value})}
                                fullWidth
                                margin="dense"
                            />
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddProductDialog(false)}>Cancel</Button>
                    <Button onClick={handleAddProduct} color="primary">Add</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openDeleteProcedureDialog}
                onClose={() => handleCloseDeleteProcedureDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete the selected row(s)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDeleteProcedureDialog(false)} sx={{color: 'red', fontSize: '1rem'}}>
                        No
                    </Button>
                    <Button onClick={() => handleCloseDeleteProcedureDialog(true)} sx={{color: '#fff', fontSize: '1rem'}} autoFocus>
                        Yes
                    </Button>
                </DialogActions>

            </Dialog>

        </Box>

    );
};

export default DataTable;