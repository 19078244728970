import React from 'react';
import {Box, Button, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const Igrafx = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="IGRAFX" subtitle="iGrafx User Interface" />


            </Box>
            <Box>
                <p>Here comes iGrafx utilities.</p>
            </Box>
        </Box>
    );
};

export default Igrafx;
