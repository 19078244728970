import React, { useState, useEffect } from "react";
import {Link} from "react-scroll";
import {useNavigate} from "react-router-dom";


function PrivacyPolicy() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page in history
    };
    return (
        <div id="privacypolicy">
            <div className="impressum-section-text-container">
                <h2 className="primary-heading">Privacy Policy</h2>
                <h3 className="primary-subheading">1. Data Controller</h3>
                <p className="primary-text">The Data Controller according to the General Data Protection Regulation (GDPR) is: <br/><br/><strong>Somos-Co GmbH</strong><br/>Bruder Kostka Gasse 18/1<br/>2372 Gießhübl bei Wien<br/>Email: peter.somos@somos-co.com<br/>Phone: +43 676 95 35 217</p>
                <h3 className="primary-subheading">2. Collection and Storage of Personal Data and the Purpose of Their Use</h3>
                <p className="primary-text">When visiting this website, data such as your IP address, date and time of
                    access, the browser and operating system used, and other technical details are automatically
                    collected. This data is collected to ensure the proper functioning of the website and to improve the
                    user experience. It is not used for any other purposes.<br/>Additionally, we collect personal data
                    that you voluntarily provide to us in the context of an inquiry, registration, or contact request,
                    e.g., when signing up for a newsletter or inquiring about our products and services.</p>
                <h3 className="primary-subheading">3. Sharing of Data</h3>
                <p className="primary-text">Your personal data will only be shared with third parties if this is
                    necessary for the execution of a contract, if you have explicitly consented to it, or if there is a
                    legal obligation to do so. In all other cases, your data will not be shared with third parties.</p>
                <h3 className="primary-subheading">4. Cookies</h3>
                <p className="primary-text">Our website uses cookies to enhance the user experience. Cookies are small
                    text files stored on your device that allow us to recognize you on subsequent visits. You can
                    disable cookies in your browser settings, but this may limit the functionality of the website.</p>
                <h3 className="primary-subheading">5. Your Rights as a Data Subject</h3>
                <p className="primary-text">You have the right:<br /><li>to request information about the personal data we store,</li>
                    <li>to request the correction or deletion of your data,</li>
                    <li>to request the restriction of processing,</li>
                    <li>to object to the processing of your data,</li>
                    <li>to request data portability.</li>
                    <br />If you wish to exercise any of these rights, you can contact us at any time.</p>
                <h3 className="primary-subheading">6. Data Retention</h3>
                <p className="primary-text">We store your personal data only as long as necessary to fulfill the
                    purposes mentioned above, or as required by legal retention obligations.</p>
                <h3 className="primary-subheading">7. Changes to the Privacy Policy</h3>
                <p className="primary-text">We reserve the right to update this Privacy Policy from time to time in
                    order to adapt it to changes in legal requirements or data processing practices..</p>
                <h3 className="primary-subheading">8. Contact</h3>
                <p className="primary-text">For any questions regarding data protection or to exercise your rights, please contact:<br/><br/><strong>Somos-Co GmbH</strong><br/> Bruder Kostka Gasse 18/1<br/>2372 Gießhübl bei Wien<br/>Email: peter.somos@somos-co.com<br/>Phone: +43 676 95 35 217</p>
                <button className="secondary-button" onClick={handleGoBack}>Go Back</button>
            </div>
        </div>
    )
        ;
};

export default PrivacyPolicy;
