import React, {useContext} from "react";
import Logo from "../../../assets/SomoS_Co_Logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import {LanguageContext} from "./LanguageContext";
import translations from "./translations";

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language]; // Short for "translate"
  return (
      <div className="footer-wrapper">
        {/* New container div with footer-content class */}
        <div className="footer-content">
          <div className="footer-section-one">
            <div className="footer-logo-container">
              <img src={Logo} alt="SomoS-Co Logo"/>
            </div>
            <div className="tooltip-container">
              <div className="tooltip">
                <div className="profile">
                  <div className="user">
                    <div className="img">Ui</div>
                    <div className="details">
                      <div className="name">User</div>
                      <div className="username">@username</div>
                    </div>
                  </div>
                  <div className="about">500+ Connections</div>
                </div>
              </div>
              <div className="text">
                <a className="icon" href="https://linkedin.com/">
                  <div className="layer">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className="fab fa-linkedin">
                        <svg viewBox="0 0 448 512" height="1em">
                          <path
                              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>
                      </span>
                  </div>
                  <div className="text">LinkedIn</div>
                </a>
              </div>
            </div>
            {/*<div className="footer-icons">


              <FaFacebookF/>
            </div>*/}

          </div>
          <div className="footer-section-two">
            <div className="footer-section-columns">
              <span>{t.footer_text1}</span>
              <span>{t.footer_text2}</span>
              <span>{t.footer_text3}</span>
              <span>{t.footer_text4}</span>

            </div>
            <div className="footer-section-columns">
              <span>{t.footer_text5}</span>
              <span>{t.footer_text6}</span>
            </div>
          </div>
        </div>
        {/* End of footer-content */}
      </div>
  );
};

export default Footer;
