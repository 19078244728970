import React, { useState, useEffect } from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/server-illustration-2.png";
import { Link } from "react-scroll";
import { LanguageContext } from './LanguageContext'; // Adjust the import path as necessary
import translations from './translations';
import { useContext } from "react";

const About = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"
    const [isVisible, setIsVisible] = useState(false);

    const onScroll = () => {
        const aboutSection = document.getElementById('about');
        const aboutSectionTop = aboutSection.getBoundingClientRect().top;
        const triggerPoint = window.innerHeight - 100; // Adjust as needed

        setIsVisible(aboutSectionTop < triggerPoint);
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div className={`about-section-container fade-in-about ${isVisible ? 'active' : ''}`} id="about">
            <div className="about-background-image-container">
                <img src={AboutBackground} alt="About SomoS-Co Background"
                     className={`slide-in ${isVisible ? 'active' : ''}`}/>
            </div>
            <div className="about-section-image-container">
                <img src={AboutBackgroundImage} alt="About SomoS-Co"
                     className={`hover-effect ${isVisible ? 'active' : ''}`}/>
            </div>
            <div className="about-section-text-container">
                <p className="primary-subheading">{t.aboutUs}</p>
                <h1 className="primary-heading">{t.about_heading}</h1>
                <p className="primary-text">{t.about_text1}</p>
                <p className="primary-text">{t.about_text2}</p>
                <div className="about-buttons-container">
                    <button className="secondary-button go-up"><Link to="process" smooth={true} duration={1000}
                                                                     offset={-100}>{t.about_button}</Link></button>
                </div>
            </div>
        </div>
    );
};

export default About;
