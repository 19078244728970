import React from 'react';
import {Box, Button, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const Tools = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="PYTHON TOOLS" subtitle="Collection of Python tools" />

            </Box>
            <Box>
                <p>Here you can find various Python-based tools for your tasks.</p>
            </Box>
        </Box>
    );
};

export default Tools;