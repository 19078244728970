// Testimonial.js
import React, { useContext, useEffect, useState, useRef } from "react";
import { AiFillStar } from "react-icons/ai";
import { LanguageContext } from "./LanguageContext";
import translations from "./translations";

const Testimonial = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"

    const testimonialsList = t.testimonials_list;

    // Create an array of refs, one for each testimonial
    const refs = useRef([]);
    refs.current = [];

    // State to track visibility of each testimonial
    const [visibleStates, setVisibleStates] = useState(
        Array(testimonialsList.length).fill(false)
    );

    // Function to add refs to the refs array
    const addToRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1, // Trigger when 10% of the item is visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const index = refs.current.indexOf(entry.target);
                if (entry.isIntersecting && index !== -1) {
                    setVisibleStates((prevStates) => {
                        const newStates = [...prevStates];
                        newStates[index] = true;
                        return newStates;
                    });
                    observer.unobserve(entry.target); // Stop observing once visible
                }
            });
        }, observerOptions);

        refs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            refs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, [testimonialsList.length]);

    return (
        <div className="work-section-wrapper" id="testimonials">
            <div className="work-section-top">
                <p className="primary-subheading">{t.testimonials}</p>
                <h1 className="primary-heading heading-wide">{t.testimonials_heading}</h1>
                <p className="primary-text">{t.testimonials_text}</p>
            </div>
            <div className="testimonial-section-container">
                {testimonialsList.map((testimonial, index) => (
                    <div
                        key={index}
                        ref={addToRefs}
                        className={`testimonial-item ${
                            visibleStates[index] ? "fade-in-up" : ""
                        }`}
                    >
                        <p>"{testimonial.text}"</p>
                        <div className="testimonials-stars-container">
                            {Array.from({ length: testimonial.rating }).map((_, i) => (
                                <AiFillStar key={i} />
                            ))}
                        </div>
                        <h2>{testimonial.client}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonial;
