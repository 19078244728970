import React, { useState, useEffect } from "react";
import {Link} from "react-scroll";
import {useNavigate} from "react-router-dom";


function Impressum() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page in history
    };
    return (
        <div id="impressum">
            <div className="impressum-section-text-container">
                <h2 className="primary-heading">Impressum</h2>
                <h3 className="primary-subheading">Legal Notice in accordance with § 5 TMG (Telemedia Act): <br />Somos-Co GmbH </h3>
                <p className="primary-text">Bruder Kostka Gasse 18/1 <br />2372 Gießhübl bei Wien <br />Austria</p>
                <h3 className="primary-subheading">Represented by:</h3>
                <p className="primary-text">Anita Somos <br/> Peter Somos</p>
                <h3 className="primary-subheading">Contact:</h3>
                <p className="primary-text">+43 676 95 35 217 <br/>peter.somos@somos-co.com</p>
                <h3 className="primary-subheading">Company Register Entry. </h3>
                <p className="primary-text">Landesgericht Wiener Neustadt: <strong>FN 311736 k</strong> <br />Gesellschaftsvertrag vom 23.5.2008</p>
                <h3 className="primary-subheading">VAT ID:</h3>
                <p className="primary-text">ATU64232547</p>
                <h3 className="primary-subheading">Tax Number:</h3>
                <p className="primary-text">181/8257</p>
                <h3 className="primary-subheading">Liability for Content:</h3>
                <p className="primary-text">The contents of our pages have been created with the outmost care. <br /> However, we can not guarentee the accuracy, completeness, or timeliness of the contents</p>
                <h3 className="primary-subheading">Liability for Links:</h3>
                <p className="primary-text">Our offer contains links to external websites of third parties, on whose content we have no influence. Therefore, we cannot assume any liability for these external contents.</p>
                <h3 className="primary-subheading">Copyright:</h3>
                <p className="primary-text">The content and works created by the site operators on these pages are subject to Austrian copyright law. <br />Contributions from third parties are marked as such.</p>

                <button className="secondary-button" onClick={handleGoBack}>Go Back</button>

            </div>


        </div>
    )
        ;
};

export default Impressum;
