import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/SomoS_Co_Logo-small3.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-scroll" ;
import translations from "./translations";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";

const Home = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"

    return (
        <div className="home-container" id="home">
            <Navbar/>
            <br/><br/><br/><br/>
            <div className="home-banner-container fade-in">
                <div className="home-bannerImage-container">
                    <img src={BannerBackground} alt="SomoS-Co Strategy"/>
                </div>
                <div className="home-text-section">
                    <h1 className="primary-heading showColor">{t.home_heading}</h1>
                    <p className="primary-text">{t.home_text1}</p>
                    <p className="primary-text">{t.home_text2}</p>
                    <p className="primary-text">{t.home_text3}</p>
                </div>
                <div className="home-image-section">
                    <img src={BannerImage} alt="SomoS-Co Consulting" style={{cursor: 'grab'}}/>
                </div>
            </div>
            {/* Service Highlights Section (New Section) */}
            <div className="service-highlights-container">
                <div className="service-highlight">
                    <i className="fas fa-robot service-icon"></i>
                    <h3>{t.service_ai_title}</h3> {/* Translation key for AI Consulting */}
                    <p>{t.service_ai_description}</p> {/* Translation key for AI description */}
                </div>
                <div className="service-highlight">
                    <i className="fas fa-sync service-icon"></i>
                    <h3>{t.service_digital_title}</h3> {/* Translation key for Digital Transformation */}
                    <p>{t.service_digital_description}</p> {/* Translation key for Digital Transformation description */}
                </div>
                <div className="service-highlight">
                    <i className="fas fa-cogs service-icon"></i>
                    <h3>{t.service_process_title}</h3> {/* Translation key for Process Optimization */}
                    <p>{t.service_process_description}</p> {/* Translation key for Process Optimization description */}
                </div>
                <div className="service-highlight">
                    <i className="fas fa-cogs service-icon"></i>
                    <h3>{t.service_operational_title}</h3> {/* Translation key for Operational Excellence */}
                    <p>{t.service_operational_description}</p> {/* Translation key for Operational Excellence description */}
                </div>
            </div>
            {/* End of Service Highlights Section */}
            <button className="consultation-button">
                <Link to="testimonials" smooth={true} duration={1000} offset={-100}>{t.testimonials}</Link>
                <FiArrowRight/>
            </button>
            {/* Button Container for alignment */}
            <div className="button-container">
                <button className="secondary-button">
                    <Link to="about" smooth={true} duration={1000} offset={-100}>{t.home_button}</Link>
                    <FiArrowRight/>
                </button>

                <button className=" secondary-button consultation-button">
                    <a href="/contact" style={{textDecoration: 'none', color: 'inherit'}}>
                        {t.home_consultation_button} {/* Use a new translation key */}
                    </a>
                </button>
            </div>
        </div>
    );
};


export default Home;
