import {Box, Button, TextField} from '@mui/material';
import {Formik} from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import axios from "axios";
import AuthService from "../../components/AuthService";
import {useNavigate} from "react-router-dom";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import React, { useState, useEffect } from 'react';

const initialValues = {
    firstName: '',
    lastName: '',
    anrede: '',
    email: '',
    password: '',
    confirmPassword: ''
};
const userSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    anrede: yup.string().required('Title is required'), // Add this validation
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match')
})


const TempForm = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [token, setToken] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token');
        if (token) {
            AuthService.validateToken(token).then(isValid => {
                if (!isValid) {
                    navigate('/login');
                } else {
                    setToken(token);
                }
            });
        } else {
            navigate('/login');
        }
    }, [navigate]);
    const handleFormSubmit = async (values) => {
        const { firstName, lastName, anrede, email, password, confirmPassword } = values;

        // Check if passwords match
        if (password !== confirmPassword) {
            console.error("Passwords do not match.");
            return;
        }

        // Include the token in the registration data
        const registrationData = { ...values, token };

        try {
            const response = await AuthService.registerWithToken(registrationData);
            console.log("Registration successful", response.data);
            navigate('/login');
        } catch (error) {
            console.error("Registration failed: ", error);
        }
    };
    return(
        <Box m="20px">
            <Header title="CREATE USER" subtitle="Create a New User Profile"/>

            <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={userSchema}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (

                    <form onSubmit={handleSubmit}>
                        <Box position="relative" m="-10px" mr="-10px" display="grid"
                             gap="25px"
                             gridTemplateColumns="repeat(4, minmax(0, 1fr)"
                             sx={{
                                 "& > div:": {gridColumn: isNonMobile ? undefined : 'span 4'},
                             }}
                        >
                            <FormControl fullWidth variant="filled" sx={{ gridColumn: isNonMobile ? 'span 4' : 'span 4' }}>
                                <InputLabel id="anrede-label">Title</InputLabel>
                                <Select
                                    labelId="anrede-label"
                                    id="anrede"
                                    name="anrede"
                                    value={values.anrede}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Title"
                                    error={!!touched.anrede && !!errors.anrede}
                                >
                                    <MenuItem value="Mr">Mr</MenuItem>
                                    <MenuItem value="Mrs">Mrs</MenuItem>
                                </Select>
                                {!!touched.anrede && !!errors.anrede && (
                                    <Box color="error.main" mt={2}>
                                        {errors.anrede}
                                    </Box>
                                )}
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                name="firstName"
                                label="First Name"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName}
                                FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                                sx={{gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                name="lastName"
                                label="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                                FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                                sx={{gridColumn: "span 2"}}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="email"
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                                sx={{gridColumn: "span 4"}}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                name="password"
                                label="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                                sx={{gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                name="confirmPassword"
                                label="Confirm Password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.confirmPassword && !!errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                                sx={{gridColumn: "span 2"}}
                            />
                            <Box display="flex" mt="-10px">
                                <Button type="submit" variant="contained" color="secondary">
                                    Register
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default TempForm;