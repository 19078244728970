import React, { useState, useEffect } from 'react';
import "./index.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Team from "./Components/Team";
import Loader from "../../Loader";
import { LanguageProvider } from "./Components/LanguageContext";
// Importing images
import AboutBackground from "./Assets/about-background.png";
import AboutBackgroundImage from "./Assets/server-illustration-2.svg";
import PickMeals from "./Assets/pick-meals-image.png";
import ChooseMeals from "./Assets/choose-image.png";
import DeliveryMeals from "./Assets/delivery-image.png";
import ProfilePic from "./Assets/john-doe-image.png";
import BannerBackground from "./Assets/home-banner-background.png";
import BannerImage from "./Assets/SomoS_Co_Logo-small3.png";
import Logo from "../../assets/SomoS_Co_Logo.png";
import flagUSA from "./Assets/usa.jpg";
import flagGermany from "./Assets/germany.png";
import flagHungary from "./Assets/hungary.jpg";


const imagesToPreload = [
    AboutBackground,
    AboutBackgroundImage,
    PickMeals,
    ChooseMeals,
    DeliveryMeals,
    ProfilePic,
    BannerBackground,
    BannerImage,
    Logo,
    flagUSA,
    flagGermany,
    flagHungary
];

const LandingPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const imagePromises = imagesToPreload.map(src => {
            return new Promise(resolve => {
                const img = new Image();
                img.onload = resolve;
                img.src = src;
            });
        });

        Promise.all(imagePromises).then(() => {
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <div className="loading-container"><Loader/></div>;
    }

    return (
        <div className="App">
            <LanguageProvider>
                <Home/>
                <About/>
                <Work/>
                <br/>
                <Team />
                <Testimonial/>
                <br/>
                <Contact/>

                <Footer/>
            </LanguageProvider>
        </div>
    );
};

export default LandingPage;
