// AuthService.js
import axios from 'axios';

const API_URL = 'https://my.somos-co.com/api/authenticate';

class AuthService {
    login(email, password) {
        console.log("Attempting login with: ", email);
        return axios
            .post(API_URL, { email, password })
            .then(response => {
                console.log("Login response:", response);
                if (response.data.jwt && response.data.refreshToken) {
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                    localStorage.setItem('user', JSON.stringify(response.data));
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
                    return true;
                }
                return false;
            })
            .catch(error => {
                console.error("Login error:", error);
                return false;
            });
    }

    register(firstName, lastName, anrede, email, password) {
        const registrationUrl = 'https://my.somos-co.com/api/users'; // Adjust this if you have a different registration endpoint
        return axios
            .post(registrationUrl, {
                vorname: firstName,
                name: lastName,
                anrede: anrede,
                email: email,
                password: password
            })
            .then(response => {
                console.log("Registration response:", response);
                return response.data;
            })
            .catch(error => {
                console.error("Registration error:", error.response);
                throw error;
            });
    }

    validateToken(token) {
        return axios.get(`https://my.somos-co.com/api/users/validate-token?token=${token}`)
            .then(response => response.status === 200)
            .catch(error => {
                console.error("Token validation error:", error);
                return false;
            });
    }

    registerWithToken(registrationData) {
        const registrationUrl = 'https://my.somos-co.com/api/users'; // Regular registration endpoint
        return axios.post(registrationUrl, registrationData)
            .then(response => {
                console.log("Registration with token response:", response);
                return response.data;
            })
            .catch(error => {
                console.error("Registration with token error:", error.response);
                throw error;
            });
    }

    logout() {
        console.log("Logging out user");
        localStorage.removeItem('user');
        delete axios.defaults.headers.common['Authorization'];
    }
    getCurrentUserDetails() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.additionalDetails) {
            return {
                firstName: user.additionalDetails.firstName,
                lastName: user.additionalDetails.lastName,
                role: user.additionalDetails.role,
            };
        }
        // Return a default object if no user is found
        return {
            firstName: 'Guest',
            lastName: '',
            role: 'Guest',
        };
    }
    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        return axios
            .post('https://my.somos-co.com/api/refresh', { refreshToken })
            .then(response => {
                if (response.data.jwt) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.jwt}`;
                    return true;
                }
                return false;
            })
            .catch(error => {
                console.error("Refresh Token error:", error);
                return false;
            });
    }
    isTokenExpired(token) {
        if (!token) {
            console.log("Token is null or undefined.");
            return true;
        }
        const now = Date.now().valueOf() / 1000;
        console.log("Current time (in seconds since epoch):", now);
        console.log("Token expiration time:", token.exp);

        const tokenIsExpired = token.exp < now;
        console.log("Is token expired?:", tokenIsExpired);
        return tokenIsExpired;
    }

    isAuthenticated() {
        const user = this.getCurrentUser();
        return user && user.jwt && !this.isTokenExpired(this.parseJwt(user.jwt));
    }

    parseJwt(token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            const parsedToken = JSON.parse(jsonPayload);
            console.log("Parsed Token:", parsedToken);
            return parsedToken;
        } catch (error) {
            console.error("Error parsing JWT:", error);
            return null;
        }
    }

    // Call this method in your AuthProvider to set initial auth state
    initAuth() {
        const user = this.getCurrentUser();
        console.log("InitAuth - Current User:", user);

        if (user && user.jwt) { // Ensure that user.jwt is not undefined
            console.log("InitAuth - Token present, validating...");
            const tokenIsValid = !this.isTokenExpired(this.parseJwt(user.jwt));
            console.log("InitAuth - Is token valid? ", tokenIsValid);

            if (tokenIsValid) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${user.jwt}`;
                console.log("InitAuth - Token valid, user authenticated");
                return true;
            } else {
                console.log("InitAuth - Token invalid or expired, logging out user");
                this.logout();
            }
        } else {
            console.log("InitAuth - No user or token found in local storage");
        }
        return false;
    }
}

export default new AuthService();
