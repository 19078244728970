// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (notification) => {
        setNotifications((prev) => [...prev, { ...notification, id: Date.now() }]);
    };

    const removeNotification = (id) => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ addNotification, removeNotification }}>
            {children}
            {/* Render notifications here */}
            <div className="notification-container">
                {notifications.map((notification) => (
                    <div key={notification.id} className="notification" onClick={() => removeNotification(notification.id)}>
                        <strong>{notification.title}</strong> - {notification.message}
                    </div>
                ))}
            </div>
        </NotificationContext.Provider>
    );
};
