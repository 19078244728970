import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { fetchDimensionData, setDimensionSelection } from '../../store';

const DimensionDropdown = ({ label, tableName, onDimensionChange }) => {
    const dispatch = useDispatch();
    const dimensionData = useSelector(state => state.dimensions.dimensionData[tableName] || []);
    const selectedValue = useSelector(state => state.dimensions.dimensionSelections[tableName] || '');
    const loading = useSelector(state => state.dimensions.loading);
    const [localSelectedValue, setLocalSelectedValue] = useState('');

    useEffect(() => {
        dispatch(fetchDimensionData({ tableName }));
    }, [dispatch, tableName]);

    useEffect(() => {
        // This check ensures we don't set an invalid value if the data hasn't loaded yet or if selectedValue is not in the new data.
        const validValue = dimensionData.some(item => item.dimID.toString() === selectedValue);
        setLocalSelectedValue(validValue ? selectedValue : '');
    }, [dimensionData, selectedValue]);

    const handleSelectionChange = (event) => {
        const newDimID = event.target.value;
        dispatch(setDimensionSelection({ tableName, selection: newDimID }));
        if (onDimensionChange) {
            onDimensionChange(tableName, newDimID);
        }
    };

    console.log("Dimension Data:", dimensionData); // Log the dimension data to check what's being loaded

    return (
        <FormControl variant="outlined" size="small" margin="dense" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={localSelectedValue}
                onChange={handleSelectionChange}
                MenuProps={{
                    PaperProps: {
                        style: { maxHeight: '50vh' },
                    },
                }}
            >
                {loading ? (
                    <MenuItem value="">Loading...</MenuItem>
                ) : (
                    dimensionData.map((item) => (
                        <MenuItem key={item.dimID} value={item.dimID.toString()}>
                            {item.name}
                        </MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
    );
};

export default DimensionDropdown;
