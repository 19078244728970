import React, {useContext, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import AuthService from './AuthService';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from './AuthContext';
import './Navbar.css'; // Make sure the path to the CSS file is correct
import logoImage from '../assets/SomoS_Co_Logo.png'; // Adjust the path if necessary
const Navbar = () => {
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const headerRef = useRef(); // Add this ref to your header div
    const location = useLocation(); // This hook returns the location object that represents the current URL.

    // Function to determine if the link is active based on the current location
    const isActive = (pathname) => location.pathname === pathname;
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                headerRef.current.classList.add('header-shadow');
            } else {
                headerRef.current.classList.remove('header-shadow');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleLogout = () => {
        AuthService.logout();
        setIsAuthenticated(false);
        navigate('/login');
    };

    return (
        <div className="header" ref={headerRef}>
            <div className="logo"><img src={logoImage} alt="SomoS Co Logo" className="navbarlogo"/></div>
            {/* Ensure the logo is correctly placed */}
            <nav className="header-menu"> {/* Use 'nav' for semantic HTML */}
                <Link to="/database" className={`nav-item ${isActive('/database') ? 'active' : ''}`}>Database</Link>
                <Link to="/tools" className={`nav-item ${isActive('/tools') ? 'active' : ''}`}>Tools</Link>
                <Link to="/igrafx" className={`nav-item ${isActive('/igrafx') ? 'active' : ''}`}>iGrafx</Link>
            </nav>
            <div className="user-settings">
                <div className="dark-light">
                    <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" fill="none" strokelinecapp="round"
                         strokeLinejoin="round">
                        <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"/>
                    </svg>
                </div>
                <div className="user-menu">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="2" strokelinecapp="round" strokeLinejoin="round"
                         className="feather feather-square">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                    </svg>
                </div>
                {/*<img className="user-profile" src="https://www.svgrepo.com/show/506667/person.svg" alt=""/>*/}
                <svg className="user-profile" fill="#EE9203" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"
                     stroke="#EE9203">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokelinecapp="round" strokeLinejoin="round" stroke="#CCCCCC"
                       strokeWidth="0.576"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"></path>
                    </g>
                </svg>
                <div className="user-name">Botond Kovacs</div>
                <button className="search-button" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );
};

export default Navbar;
