import React, { useContext, useEffect, useState } from 'react';
import CEO from "../Assets/peter-somos.jpg";
import BusinessStrategist from "../Assets/john-doe-image.png";
import MarketingExpert from "../Assets/john-doe-image.png";
import { LanguageContext } from "./LanguageContext";
import translations from "./translations";

const teamMembers = [
    {
        name: "Peter Somos",
        position: "Manager",
        image: CEO,
        isCEO: true,
    },
    {
        name: "Vencel Somos",
        position: "IT Management",
        image: BusinessStrategist,
        isCEO: false,
    },
    {
        name: "Anita Somos",
        position: "Finance",
        image: MarketingExpert,
        isCEO: false,
    },
   
];

const Team = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.5,
                triggerOnce: true,
            }
        );

        const teamSection = document.querySelector('.team-section-bottom');
        if (teamSection) {
            observer.observe(teamSection);
        }

        return () => {
            if (teamSection) {
                observer.unobserve(teamSection);
            }
        };
    }, []);

    return (
        <div className="team-wrapper" id="team">
            <div className="team-section-top">
                <p className="primary-subheading">{t.team_subheading}</p>
                <h1 className="primary-heading">{t.team_heading}</h1>
                <p className="primary-text mobile-left">{t.team_text}</p>
            </div>
            {/*         <div className={`team-section-bottom ${isVisible ? 'fade-in-team' : ''}`}>
                {teamMembers.filter(member => member.isCEO).map((member, index) => (
                    <div key={index} className="team-member ceo">
                        <img src={member.image} alt={member.name} className="team-member-image"/>
                        <h2 className="team-member-name">{member.name}</h2>
                        <p className="team-member-position">{member.position}</p>
                    </div>
                ))}
                <div className="other-members">
                    {teamMembers.filter(member => !member.isCEO).map((member, index) => (
                        <div key={index} className="team-member">
                            <img src={member.image} alt={member.name} className="team-member-image"/>
                            <h2 className="team-member-name">{member.name}</h2>
                            <p className="team-member-position">{member.position}</p>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    );
}

export default Team;
