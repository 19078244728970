import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DataTable from './DataTable';
import SQLSidebar from './Sidebar';
import Topbar from './Topbar';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchTableNames,
    fetchTableData,
    addRow,
    updateRow,
    deleteRow,
    setSelectedTable,
    removeFromHistory,
} from '../../store';

const DatabaseManagement = () => {
    const dispatch = useDispatch();
    const {
        tables,
        selectedTable,
        tableData,
        columns, // Assuming columns is an array of { field, headerName } objects
        loading,
        error,
        primaryKeyColumnName,
    } = useSelector(state => state.database);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    useEffect(() => {
        dispatch(fetchTableNames());
    }, [dispatch]);

    useEffect(() => {
        if (selectedTable) {
            dispatch(fetchTableData(selectedTable));
        }
    }, [selectedTable, dispatch]);

    const handleTableSelection = (table) => {
        dispatch(setSelectedTable(table));
    };

    // Assuming CRUD operations are handled via Redux thunks or similar
    const handleAddRow = (newRow) => {
        dispatch(addRow(newRow));
    };

    const handleUpdateRow = (updatedRow) => {
        dispatch(updateRow(updatedRow));
    };

    const handleDeleteRows = (ids) => {
        ids.forEach(id => dispatch(deleteRow(id)));
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box display="flex" height="100vh" overflow="hidden">
            <SQLSidebar
                tables={tables}
                onSelectTable={handleTableSelection}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
            />
            <Box flex={1} overflow="auto">
                <Topbar
                    selectedTable={selectedTable}
                    onRemoveFromHistory={index => dispatch(removeFromHistory(index))}
                />
                <DataTable
                    initialData={tableData}
                    columns={columns.map(col => ({ name: col.headerName, field: col.field }))}
                    onAddRow={handleAddRow}
                    onUpdateRow={handleUpdateRow}
                    onDeleteSelectedRows={handleDeleteRows}
                    selectedTableName={selectedTable} // Pass the selected table name here
                    primaryKeyColumnName={primaryKeyColumnName}
                />

            </Box>
        </Box>
    );
};

export default DatabaseManagement;
