import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, Box, Paper, TextField } from '@mui/material';
import Divider from "@mui/material/Divider";

const SQLSidebar = ({ tables, onSelectTable }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false); // Add a state to track focus

    const filteredTables = tables.filter(table =>
        table.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Paper sx={{ width: '15%', maxWidth: 240, height: '100%', overflow: 'auto' }}>
            <Box sx={{ bgcolor: 'background.paper', padding: '8px' }}>
                <TextField
                    label="Filter Tables"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setIsFocused(true)} // Update state on focus
                    onBlur={() => setIsFocused(false)} // Update state on blur
                    fullWidth
                    sx={{
                        marginBottom: '10px',
                        '.MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': {
                                borderColor: isFocused ? 'orange' : 'white', // Change borderColor based on focus
                            },
                            '&:hover fieldset': {
                                borderColor: 'primary.main',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'orange', // Ensure the borderColor is orange when focused
                            },
                        },
                        '.MuiInputLabel-root': { // styles for label
                            color: isFocused ? 'orange' : 'white', // Change label color based on focus
                            '&.Mui-focused': {
                                color: 'orange',
                            }
                        }
                    }}
                />
                <Divider />
                <List>
                    <ListItemText primary="Tables" sx={{
                        textAlign: 'center',
                        fontSize: 20,
                        fontWeight: 'bold',
                        color: 'white',
                        marginBottom: '10px',
                    }}/>
                    {filteredTables.map((table, index) => (
                        <ListItemButton
                            key={index}
                            onClick={() => onSelectTable(table)}
                            sx={{
                                justifyContent: 'flex-start',
                                textAlign: 'left',
                                '&:hover': {
                                    bgcolor: 'primary.main',
                                    '.MuiListItemText-primary': { // Ensures text color change on hover
                                        color: 'primary.contrastText',
                                    },
                                },
                                '&.Mui-selected': { // Background color for selected item
                                    bgcolor: 'primary.dark',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    },
                                    '.MuiListItemText-primary': {
                                        color: 'primary.contrastText',
                                    },
                                },
                            }}
                        >
                            <ListItemText primary={table} sx={{ color: 'white' }} />
                        </ListItemButton>
                    ))}
                    {/* Admin-only items */}
                    {userRole === 'ADMIN' && (
                        <>
                            <ListItemText primary="Data" sx={{ color: 'white' }} />
                            {/* Additional items for admin */}
                        </>
                    )}
                </List>
            </Box>
        </Paper>
    );
};

export default SQLSidebar;
