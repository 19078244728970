// store.js
import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiService from './services/ApiService';
import { determineIdFieldName, generateColumnsFromData } from './scenes/database/tableHelpers';
import calendarReducer from './calendarSlice';
// Define the initial state of the database slice
const initialState = {
    tables: [],
    selectedTable: '',
    tableHistory: [],
    tableData: [],
    filteredData: [],
    columns: [],
    idFieldName: 'id',
    loading: false,
    error: null,
};
export const fetchTableNames = createAsyncThunk(
    'database/fetchTableNames',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.getTableNames();
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);
export const fetchTableData = createAsyncThunk(
    'database/fetchTableData',
    async (tableName, { getState, dispatch, rejectWithValue }) => {
        const { database } = getState();
        // Check if the table is marked as a writeback table
        if (database.tables[tableName] && database.tables[tableName].isWriteback) {
            // Implement logic to handle writeback table scenario
            // For example, calling a stored procedure or loading alternative data
            console.log("Writeback table accessed, avoiding direct SQL table fetch.");
            return []; // Return an empty array or appropriate response for writeback tables
        }
        try {
            const response = await apiService.getTableData(tableName);
            const { data, primaryKeyColumnName } = response;
            dispatch(setIdFieldName(primaryKeyColumnName));

            // Generate columns based on the data, considering the dynamic ID field
            const columns = generateColumnsFromData(data, primaryKeyColumnName);
            dispatch(setColumns(columns));

            return data; // Now correctly extracting and using the data
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);
export const addRow = createAsyncThunk(
    'database/addRow',
    async ({ tableName, newRow }, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiService.addRow(tableName, newRow);
            if (response.success) {
                dispatch(fetchTableData(tableName)); // Trigger a re-fetch
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    },
    // No changes needed here as the actual update of data will be handled by fetchTableData
);
export const updateRow = createAsyncThunk(
    'database/updateRow',
    async ({ tableName, id, changes }, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiService.updateRow(tableName, id, changes);
            if (response.success) {
                // Trigger a re-fetch or update the local state as needed
                dispatch(fetchTableData(tableName));
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

export const deleteRow = createAsyncThunk(
    'database/deleteRow',
    async ({ tableName, id }, { rejectWithValue }) => {
        try {
            await apiService.deleteRow(tableName, id);
            return id;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

export const executeDBWritebacks = createAsyncThunk(
    'database/executeDBWriteback',
    async (parameters, { dispatch, rejectWithValue }) => {
        try {
            // Assuming apiService has a method executeDBWriteback that takes the necessary parameters
            const response = await apiService.executeDBWriteback(parameters);
            if (response.success) {
                // Optionally, refresh table data or handle success in a specific way
                console.log('DBWriteback executed successfully');
            }
            if (!response.success) {
                // Assuming the response has a success flag, adjust based on your API's structure
                throw new Error('Backend returned an error');
            }
            console.log('miageci');
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

export const executeDBMultipleWritebacks = createAsyncThunk(
    'database/executeDBMultipleWritebacks',
    async (parameters, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiService.executeDBMultipleWritebacks(parameters);
            if (response.success) {
                // Optionally, refresh table data or handle success in a specific way
                console.log('DBMultipleWritebacks executed successfully');
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Thunk for executing USPDynamicPivot stored procedure
export const executeUSPDynamicPivot = createAsyncThunk(
    'database/executeUSPDynamicPivot',
    async (parameters, { dispatch, rejectWithValue }) => {
        try {
            console.log("Dispatching executeUSPDynamicPivot with parameters:", parameters);
            const response = await apiService.executeUSPDynamicPivot(parameters);
            if (response.success) {
                // Optionally, refresh table data or handle success in a specific way
                console.log('USPDynamicPivot executed successfully');
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

export const executeQuery = createAsyncThunk(
    'database/executeQuery',
    async ({ query, parameters }, { rejectWithValue }) => {
        try {
            // Ensuring parameters is always an object
            const params = parameters || {};
            console.log("Dispatching query:", query, "with parameters:", params); // Debugging line
            if (Object.keys(params).length > 0) {
                return await apiService.executeDynamicQuery(query, params);
            } else {
                return await apiService.executeSimpleQuery(query);
            }
        } catch (error) {
            console.error("Error executing query:", error);
            return rejectWithValue(error.toString());
        }
    }
);

// Create a slice for the database operations
const databaseSlice = createSlice({
    name: 'database',
    initialState,
    reducers: {
        filterData(state, action) {
            const { dimension, value } = action.payload;
            // Update filteredData based on the dimension and value
            state.filteredData = state.tableData.filter(item => item[dimension] === value);
        },
        setTables(state, action) {
            state.tables = action.payload;
        },
        setSelectedTable(state, action) {
            state.selectedTable = action.payload;
            if (!state.tableHistory.includes(action.payload)) {
                state.tableHistory.unshift(action.payload);
            }
        },
        setTableData(state, action) {
            state.tableData = action.payload;
        },
        setColumns(state, action) {
            state.columns = action.payload;
        },
        setIdFieldName(state, action) {
            state.primaryKeyColumnName = action.payload;
        },
        removeFromHistory(state, action) {
            const index = action.payload;
            if (index > -1) {
                state.tableHistory.splice(index, 1);
            }
        },
        fetchTableDataBegin(state) {
            state.loading = true;
            state.error = null;
        },
        fetchTableDataSuccess(state, action) {
            state.tableData = action.payload;
            state.loading = false;
        },
        fetchTableDataFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        addRowBegin(state) {
            state.loading = true;
        },
        addRowSuccess(state, action) {
            state.tableData.push(action.payload);
            state.loading = false;
        },
        addRowFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateRowBegin(state) {
            state.loading = true;
        },
        updateRowSuccess(state, action) {
            const index = state.tableData.findIndex(row => row.id === action.payload.id);
            if (index !== -1) {
                state.tableData[index] = action.payload;
            }
            state.loading = false;
        },
        updateRowFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteRowBegin(state) {
            state.loading = true;
        },
        deleteRowSuccess(state, action) {
            state.tableData = state.tableData.filter(row => row.id !== action.payload);
            state.loading = false;
        },
        deleteRowFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        // Additional reducers as needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableNames.fulfilled, (state, action) => {
                state.tables = action.payload;
                state.loading = false;
            })
            .addCase(fetchTableData.fulfilled, (state, action) => {
                state.tableData = action.payload;
                state.loading = false;
            })
            .addCase(addRow.fulfilled, (state, action) => {
                // Refreshing table data after a row is successfully added
                state.tableData.push(action.payload);
                state.loading = false;
            })
            .addCase(updateRow.fulfilled, (state, action) => {
                // Refreshing table data after a row is successfully updated
                const index = state.tableData.findIndex(row => row[state.idFieldName] === action.payload[state.idFieldName]);
                if (index !== -1) {
                    state.tableData[index] = { ...state.tableData[index], ...action.payload };
                }
                state.loading = false;
            })
            .addCase(deleteRow.fulfilled, (state, action) => {
                // Removing the deleted row from table data
                state.tableData = state.tableData.filter(row => row[state.idFieldName] !== action.payload);
                state.loading = false;
            })
            .addCase(executeDBWritebacks.fulfilled, (state, action) => {
                // Optionally handle state changes after DBWritebacks execution
                console.log('DBWriteback executed successfully');
                // This is where you might refresh data or update state based on the operation's success
            })
            .addCase(executeDBMultipleWritebacks.fulfilled, (state, action) => {
                // Handling successful execution of DBMultipleWritebacks
                console.log('DBMultipleWritebacks executed successfully');
                // Similar to executeDBWritebacks, handle any necessary state updates or refreshes here
            })
            .addCase(executeUSPDynamicPivot.fulfilled, (state, action) => {
                // Handling successful execution of USPDynamicPivot
                console.log('USPDynamicPivot executed successfully');
                // Implement state changes or data refreshes as needed
            })
            // Ensure to handle rejected states for new thunks if necessary
            .addCase(executeDBWritebacks.rejected, (state, action) => {
                console.error('executeDBWritebacks failed:', action.error);
                state.error = action.error.message || 'An unknown error occurred';
            })
            .addCase(executeDBMultipleWritebacks.rejected, (state, action) => {
                console.error('executeDBMultipleWritebacks failed:', action.error);
                state.error = action.error.message || 'An unknown error occurred';
            })
        /*.addCase(executeUSPDynamicPivot.rejected, (state, action) => {
            console.error('executeUSPDynamicPivot failed:', action.error);
            state.error = action.error.message || 'An unknown error occurred';
        });*/
    },
});

const dimensionsSlice = createSlice({
    name: 'dimensions',
    initialState: {
        dimensionData: {},
        dimensionSelections: {},
        loading: false,
        error: null,
    },
    reducers: {
        fetchDimensionDataStart(state, action) {
            state.loading = true;
            state.error = null;
        },
        fetchDimensionDataSuccess(state, action) {
            state.dimensionData[action.payload.tableName] = action.payload.data;
            state.loading = false;
        },
        fetchDimensionDataFailure(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        setDimensionSelection(state, action) {
            const { tableName, selection } = action.payload;
            state.dimensionSelections[tableName] = selection;
        },
    },
});

export const fetchDimensionData = createAsyncThunk(
    'dimensions/fetchDimensionData',
    async ({ tableName }, { dispatch }) => {
        try {
            dispatch(fetchDimensionDataStart());
            const data = await apiService.getTableData(tableName);
            dispatch(fetchDimensionDataSuccess({ tableName, data: data.data }));
        } catch (error) {
            dispatch(fetchDimensionDataFailure({ error: error.toString() }));
        }
    }
);

// Export actions for use in components
export const {
    setTables,
    setSelectedTable,
    setTableData,
    setColumns,
    setIdFieldName,
    removeFromHistory,
    fetchTableDataBegin,
    fetchTableDataSuccess,
    fetchTableDataFailure,
    addRowBegin,
    addRowSuccess,
    addRowFailure,
    updateRowBegin,
    updateRowSuccess,
    updateRowFailure,
    deleteRowBegin,
    deleteRowSuccess,
    deleteRowFailure,
    filterData,
} = databaseSlice.actions;

// Actions for fetching dimension data
export const {
    fetchDimensionDataStart,
    fetchDimensionDataSuccess,
    fetchDimensionDataFailure,
    setDimensionSelection
} = dimensionsSlice.actions;


// Configure the store
const store = configureStore({
    reducer: {
        calendar: calendarReducer,
        database: databaseSlice.reducer,
        dimensions: dimensionsSlice.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
