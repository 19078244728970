import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const initialValues = {
    email: '',
};

const inviteUserSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
});

const InviteUser = () => {
    const handleSubmit = async (values) => {
        try {
            await axios.post('https://my.somos-co.com:8081/api/users/invite', { email: values.email });
            alert('Invitation sent successfully');
        } catch (error) {
            console.error('Error sending invitation', error);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={inviteUserSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                    <Box
                        m="20px"
                        position="relative"
                        display="grid"
                        gap="25px"
                        gridTemplateColumns="repeat(1, minmax(0, 1fr)"
                    >
                        <TextField
                            fullWidth
                            variant="filled"
                            type="email"
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            FormHelperTextProps={{ style: { minHeight: '1em', marginTop: '2px' } }} // reserve space for error text
                        />
                        <Box display="flex" mt="-10px">
                            <Button type="submit" variant="contained" color="primary">
                                Invite
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default InviteUser;
