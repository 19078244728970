// tableHelpers.js

export const determineIdFieldName = (data) => {
    const possibleIdFields = ["id", "user_id", "ID"]; // Ensure you include all possible ID field names
    if (data.length > 0) {
        const sampleRow = data[0];
        const foundKey = Object.keys(sampleRow).find(key => possibleIdFields.includes(key));
        return foundKey || "id"; // Default to "id" if none of the possible fields are found
    }
    return "id"; // Default to "id" if data is empty
};

export const generateColumnsFromData = (data, idFieldName) => {
    if (data.length === 0) return [];

    // Use the first row to determine the columns
    const sampleRow = data[0];
    return Object.keys(sampleRow).map(key => ({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the header name
        width: 150, // Set a default width, you can customize this as needed
        // Add any other column config you might need
    }));
};
