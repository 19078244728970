import React, {useContext, useEffect, useRef} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import DatabaseManagement from './scenes/database/DatabaseManagement';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Tools from './scenes/tools';
import Igrafx from './scenes/igrafx';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Team from './scenes/team';
import Invoices from './scenes/invoices';
import Contacts from './scenes/contacts';
import Bar from './scenes/bar';
import Form from './scenes/form';
import Line from './scenes/line';
import Pie from './scenes/pie';
import FAQ from './scenes/faq';
import Geography from './scenes/geography';
import Calendar from './scenes/calendar';
import InviteUser from "./scenes/invite";
import TempForm from "./scenes/tempregister";
import Landing from "./scenes/landingpage";
import Impressum from "./scenes/landingpage/Components/Impressum"
import PrivacyPolicy from "./scenes/landingpage/Components/PrivacyPolicy"
import { AuthContext } from './components/AuthContext';
import { Notifications } from './components/Notifications';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './components/Notifications.css';
import './App.css';
import AuthService from "./components/AuthService";
import { usePageTransitions } from './usePageTransitions';
import Loader from "./Loader";
import { NotificationProvider } from './components/NotificationContext';
const MainContent = () => {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);
  const showSidebarAndTopbar = isAuthenticated && location.pathname !== '/tempregister' && location.pathname !== '/';
  const currentUserDetails = isAuthenticated ? AuthService.getCurrentUserDetails() : { role: 'Guest' };
  const currentUserRole = currentUserDetails.role;
  const { withTransition } = usePageTransitions(["/login", "/dashboard"]);
  // Wrap your components with the withTransition HOC
  const LoginWithTransition = withTransition(Login);
  const DashboardWithTransition = withTransition(Dashboard);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const hasRole = (role) => {
    return currentUserRole === role;
  };
  return (
      <>
        {showSidebarAndTopbar && <Sidebar/>}
        <main className="content">
          {showSidebarAndTopbar && <Topbar/>}
          <Routes>
            <Route path="/tempregister" element={<TempForm/>}/>
            <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard"/> : <LoginWithTransition />}/>
            <Route path="/" element={<Landing />}/>
            <Route path="/impressum" element={<Impressum />}/>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
            {/*<Route path="/login" element={isAuthenticated ? <Navigate to="/"/> : <Navigate to="/login"/>}/>*/}
            {hasRole("Admin") && (
                <>
                  <Route path="/invite" element={isAuthenticated ? <InviteUser/> : <Navigate to="/login"/>}/>
                  <Route path="/team" element={isAuthenticated ? <Team /> : <Navigate to="/login"/>}/>
                  <Route path="/contacts" element={isAuthenticated ? <Contacts/> : <Navigate to="/login"/>}/>
                  {/*Checkbox does not have functionality, but you can customize the footer, you can use the selected item to do some kind of action*/}
                  <Route path="/invoices" element={isAuthenticated ? <Invoices/> : <Navigate to="/login"/>}/>
                  <Route path="/form" element={isAuthenticated ? <Form/> : <Navigate to="/login"/>}/>
                  {/* ... other admin-specific routes */}
                </>
            )}

            <Route path="/dashboard" element={isAuthenticated ? <DashboardWithTransition /> : <Navigate to="/login"/>}/>
            <Route path="/database" element={isAuthenticated ? <DatabaseManagement/> : <Navigate to="/login"/>}/>
            <Route path="/tools" element={isAuthenticated ? <Tools/> : <Navigate to="/login"/>}/>
            <Route path="/igrafx" element={isAuthenticated ? <Igrafx/> : <Navigate to="/login"/>}/>


            <Route path="/calendar" element={isAuthenticated ? <Calendar/> : <Navigate to="/login"/>}/>
            <Route path="/faq" element={isAuthenticated ? <FAQ/> : <Navigate to="/login"/>}/>
            <Route path="/bar" element={isAuthenticated ? <Bar/> : <Navigate to="/login"/>}/>
            <Route path="/pie" element={isAuthenticated ? <Pie/> : <Navigate to="/login"/>}/>
            <Route path="/line" element={isAuthenticated ? <Line/> : <Navigate to="/login"/>}/>

            <Route path="/geography" element={isAuthenticated ? <Geography/> : <Navigate to="/login"/>}/>

            <Route path="*" element={isAuthenticated ? <Dashboard/> : <Navigate to="/login"/>}/>

          </Routes>

        </main>
      </>
  );
}


function App() {
  const [theme, colorMode] = useMode();

  const {isAuthenticated, loading} = useContext(AuthContext);
  const notisContainerRef = useRef(); // Ref to the notifications container
  let notis;

  useEffect(() => {
    // Make the ref available globally
    window.notisContainerRef = notisContainerRef;
  }, []);

  useEffect(() => {
    if (notisContainerRef.current) {
      notis = new Notifications(notisContainerRef.current);
    }
    if (isAuthenticated && notis) {
      notis.create("Login Successful", "Welcome back!", 3);
    }
  }, [isAuthenticated]);
  console.log("App Rendered, isAuthenticated: ", isAuthenticated)
  if (loading) {
    return <div className="loading-container"><Loader/></div>; // Or any loading indicator
  }

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <div className="main-app">
              <div className="notifications" ref={notisContainerRef}></div>
              <MainContent />
            </div>
          </Router>

        </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;

              