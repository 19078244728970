// usePageTransitions.js
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const pageVariants = {
    initial: {
        opacity: 0,
        y: 50,
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        y: -50,
        scale: 1.1
    }
};

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
};

export const usePageTransitions = (specificPaths) => {
    const location = useLocation();
    const [enabledTransitions, setEnabledTransitions] = useState(false);

    useEffect(() => {
        setEnabledTransitions(specificPaths.includes(location.pathname));
    }, [location, specificPaths]);

    const withTransition = (Component) => (props) =>
        enabledTransitions ? (
            <AnimatePresence wait>
                <motion.div
                    key={location.pathname}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <Component {...props} />
                </motion.div>
            </AnimatePresence>
        ) : (
            <Component {...props} />
        );

    return { withTransition };
};
