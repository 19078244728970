import {Box, useTheme, Typography} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box m="20px">
            <Header title="FAQ" subtitle="Frequently Asked Questions"/>
            <Box display="flex" flexDirection="column" gap="20px">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h5">What is the purpose of this application?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The purpose of this application is to provide a starting point for developers to build a new application using the MUI library.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h5">How do I get started?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            To get started, you can clone this repository and begin building your application. 
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h5">What is the purpose of this application?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            The purpose of this application is to provide a starting point for developers to build a new application using the MUI library.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h5">How do I get started?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            To get started, you can clone this repository and begin building your application. 
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                
            </Box>
        </Box>
    );
};
export default FAQ;