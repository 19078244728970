// ApiService.js
const API_BASE_URL = 'https://my.somos-co.com/api/dynamic'; // Adjust this to your actual dynamic API base URL

export const getTableNames = async () => {
    // Assuming an endpoint that returns the available table names
    const response = await fetch(`${API_BASE_URL}/tables`);
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Failed to fetch table names');
};


export const getTableData = async (tableName) => {
    const response = await fetch(`${API_BASE_URL}/${tableName}`);
    if (response.ok) {
        const data = await response.json();
        console.log(`Data received for table ${tableName}:`, data);
        return data;
    }
    throw new Error(`Failed to fetch data for table ${tableName}`);
};

export const addRow = async (tableName, rowData) => {
    const response = await fetch(`${API_BASE_URL}/${tableName}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
    });
    if (!response.ok) {
        throw new Error(`Failed to add row to table ${tableName}`);
    }
    return await response.json();
};

export const updateRow = async (tableName, id, rowData) => {
    const response = await fetch(`${API_BASE_URL}/${tableName}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
    });
    if (!response.ok) {
        throw new Error(`Failed to update row in table ${tableName}`);
    }
};

export const deleteRow = async (tableName, id) => {
    const response = await fetch(`${API_BASE_URL}/${tableName}/${id}`, {
        method: 'DELETE',
    });
    if (!response.ok) {
        throw new Error(`Failed to delete row from table ${tableName}`);
    }
};

export const executeDBMultipleWritebacks = async (parameters) => {
    const response = await fetch(`${API_BASE_URL}/writeback/multiple`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameters),
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to execute DBMultipleWritebacks: ${errorText}`);
    }
    return await response.json();
};

export const executeDBWriteback = async (parameters) => {
    const response = await fetch(`${API_BASE_URL}/writeback/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameters),
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to execute DBWriteback: ${errorText}`);
    }
    return await response.json();
};

export const executeUSPDynamicPivot = async (parameters) => {
    const response = await fetch(`${API_BASE_URL}/writeback/pivot`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameters),
    });
    console.log("Raw response:", response);
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to execute USPDynamicPivot: ${errorText}`);
    }
    return await response.json();
};
export const getCalendarEvents = async () => {
    const response = await fetch(`${API_BASE_URL}/calendar/events`);
    if (response.ok) {
        return await response.json(); // This should be the array of events
    }
    throw new Error('Failed to fetch calendar events');
};

export const updateCalendarEvent = async (id, updates) => {
    const response = await fetch(`${API_BASE_URL}/calendar/events/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update calendar event with id ${id}: ${errorText}`);
    }
    return await response.json();
};

export const executeSimpleQuery = async (query) => {
    console.log("Executing simple query:", query); // Debugging
    const response = await fetch(`${API_BASE_URL}/query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query })
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Failed to execute simple query');
};

export const executeDynamicQuery = async (query, parameters) => {
    console.log("Executing dynamic query:", query, "with parameters:", parameters); // Debugging
    const response = await fetch(`${API_BASE_URL}/query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query, parameters })
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Failed to execute dynamic query');
};
