// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 10px;
    height: 10px; /* Ensure height is set for aspect ratio */
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%; /* Adjust positioning as needed */
    left: 50%;
    transform: translate(-50%, -50%); /* Center the loader */
}

.loader:before {
    content: '';
    position: absolute;
    inset: calc(10px * -0.5);
    border-radius: 50%;
    background: #fff;
    filter: blur(10px);
    z-index: -1;
}

.loader:after {
    content: "";
    width: 100px;
    height: 100px; /* Explicit height for aspect ratio */
    position: absolute;
    top: -45px; /* Adjust to center the gradient circle */
    left: -45px; /* Centering adjustment */
    background: conic-gradient(white, #ffb766, #fe9e0d, transparent 65%);
    border-radius: 50%;
    mask: radial-gradient(transparent calc(((100px * 0.5) - 10px) - 1px), white calc((100px * 0.5) - 10px));
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY,EAAE,0CAA0C;IACxD,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ,EAAE,iCAAiC;IAC3C,SAAS;IACT,gCAAgC,EAAE,sBAAsB;AAC5D;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa,EAAE,qCAAqC;IACpD,kBAAkB;IAClB,UAAU,EAAE,yCAAyC;IACrD,WAAW,EAAE,yBAAyB;IACtC,oEAAoE;IACpE,kBAAkB;IAClB,uGAAuG;IACvG,kCAAkC;AACtC;;AAEA;IACI;QACI,yBAAyB;IAC7B;IACA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".loader {\n    width: 10px;\n    height: 10px; /* Ensure height is set for aspect ratio */\n    background: #fff;\n    border-radius: 50%;\n    position: absolute;\n    top: 50%; /* Adjust positioning as needed */\n    left: 50%;\n    transform: translate(-50%, -50%); /* Center the loader */\n}\n\n.loader:before {\n    content: '';\n    position: absolute;\n    inset: calc(10px * -0.5);\n    border-radius: 50%;\n    background: #fff;\n    filter: blur(10px);\n    z-index: -1;\n}\n\n.loader:after {\n    content: \"\";\n    width: 100px;\n    height: 100px; /* Explicit height for aspect ratio */\n    position: absolute;\n    top: -45px; /* Adjust to center the gradient circle */\n    left: -45px; /* Centering adjustment */\n    background: conic-gradient(white, #ffb766, #fe9e0d, transparent 65%);\n    border-radius: 50%;\n    mask: radial-gradient(transparent calc(((100px * 0.5) - 10px) - 1px), white calc((100px * 0.5) - 10px));\n    animation: spin 1s infinite linear;\n}\n\n@keyframes spin {\n    from {\n        transform: rotate(360deg);\n    }\n    to {\n        transform: rotate(0deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
