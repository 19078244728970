// In a new file, e.g., calendarSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as apiService from './services/ApiService';

const initialState = {
    events: [],
    loading: false,
    error: null,
};

export const fetchEvents = createAsyncThunk(
    'calendar/fetchEvents',
    async (_, { rejectWithValue }) => {
        try {
            const events = await apiService.getCalendarEvents(); // Directly use the returned array
            // Assuming the response from getCalendarEvents() is already the array of events you need
            const formattedEvents = events.map(event => ({
                id: event.id.toString(), // Ensure id is a string for FullCalendar
                title: event.title,
                start: event.startTime, // Ensure these keys match your actual event object structure
                end: event.endTime,
            }));
            return formattedEvents;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

export const updateEvent = createAsyncThunk(
    'calendar/updateEvent',
    async ({ id, updates }, { dispatch, rejectWithValue }) => {
        try {
            await apiService.updateCalendarEvent(id, updates);
            dispatch(fetchEvents()); // Refresh events after update
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Define the calendar slice
const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload;
                state.error = null;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.error = action.payload;
            });
        // Handle other cases such as adding and deleting events
    },
});

export default calendarSlice.reducer;
