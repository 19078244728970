// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import AuthService from './AuthService';

export const AuthContext = createContext({
    isAuthenticated: false, // default value
    setIsAuthenticated: () => {}
});
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // New loading state

    useEffect(() => {
        const initAuthState = AuthService.initAuth();
        setIsAuthenticated(initAuthState);
        setLoading(false); // Set loading to false after check

        const checkTokenExpiration = async () => {
            const currentUser = AuthService.getCurrentUser();
            if (currentUser && currentUser.jwt && AuthService.isTokenExpired(AuthService.parseJwt(currentUser.jwt))) {
                const refreshed = await AuthService.refreshToken();
                if (!refreshed) {
                    AuthService.logout();
                    setIsAuthenticated(false);
                }
            }
        };

        checkTokenExpiration().then(r => console.log("Token expiration check complete"));
        const handleAuthChange = () => {
            const authState = AuthService.isAuthenticated();
            console.log("Auth State Changed:", authState);
            setIsAuthenticated(authState);
        };

        window.addEventListener('storage', handleAuthChange);
        return () => {
            window.removeEventListener('storage', handleAuthChange);
        };
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

