import React, {useState, useContext, useEffect} from 'react';
import AuthService from './AuthService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Import the provider and context
import './Login.css';
import logoImage from '../assets/SomoS_Co_Logo.png'; // Adjust the path if necessary
import { Link } from 'react-router-dom'; // Import Link
import {Notifications} from "./Notifications"; // Import the hook
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthContext);
    const handleLogin = async (e) => {
        e.preventDefault();
        console.log("Login form submitted with: ", email, password);
        let notis;
        if (window.notisContainerRef && window.notisContainerRef.current) {
            notis = new Notifications(window.notisContainerRef.current);
        }
        try {
            const isAuthenticated = await AuthService.login(email, password);
            console.log("Login successful: ", isAuthenticated);
            setIsAuthenticated(isAuthenticated);
            if (!isAuthenticated) {
                const notis = new Notifications(window.notisContainerRef.current);
                notis.create("Login Failed", "Please check your credentials.", 5, true);
            }
            /*if (isAuthenticated) {
                document.querySelector('.login-container').style.animation = 'fadeOut 1s ease-in-out forwards';
                setTimeout(() => navigate('/dashboard'), 1000);
            }*/
        } catch (error) {
            console.error("Login failed: ", error);
            setIsAuthenticated(false);
            if (window.notisContainerRef && window.notisContainerRef.current) {
                const notis = new Notifications(window.notisContainerRef.current);
                notis.create("Login Failed", "The server is down.", 5, true);
            }
        }
    };
    return (
        <div className={"login-container"}>
            <Link to="/">
                <img src={logoImage} alt="SomoS Co Logo" className="logo"/>
            </Link>
            <div className="form-1">

                <div className="background">
                    <div className="shape"></div>
                    <div className="shape"></div>
                </div>
                <form onSubmit={handleLogin}>
                    <h3>Login</h3>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <button className="mobile-login" type="submit">Log in <span className="material-icons-outlined login"></span></button>
                </form>
            </div>
        </div>
    );
}

export default Login;
