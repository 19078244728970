import React from 'react';
import { AppBar, Toolbar, Chip, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTable, removeFromHistory } from '../../store'; // Ensure actions are correctly imported

const Topbar = () => {
    const dispatch = useDispatch();
    const { selectedTable, tableHistory } = useSelector((state) => state.database); // Adjust according to your state structure

    const handleSelectTable = (tableName) => {
        dispatch(setSelectedTable(tableName));
    };

    const handleRemoveFromHistory = (index) => {
        const isRemovingSelected = tableHistory[index] === selectedTable;
        dispatch(removeFromHistory(index));
        if (isRemovingSelected) {
            // Select the next table if available, or clear selection if none
            const nextTable = tableHistory[index + 1] || tableHistory[index - 1] || '';
            dispatch(setSelectedTable(nextTable));
        }
    };

    return (
        <AppBar position="static" sx={{backgroundColor: 'rgba(41,41,41,0.51)'}}>
            <Toolbar>
                {tableHistory.map((table, index) => (
                    <Chip
                        key={table} // Assuming table names are unique, otherwise consider a different key
                        label={table}
                        onClick={() => handleSelectTable(table)}
                        onDelete={tableHistory.length > 1 ? () => handleRemoveFromHistory(index) : null} // Conditionally set onDelete
                        deleteIcon={<CloseIcon />}
                        color={selectedTable === table ? "primary" : "default"}
                        style={{
                            marginRight: 8,
                            backgroundColor: table === selectedTable ? '#ef9303' : '',
                            color: table === selectedTable ? 'white' : '',
                        }}
                    />
                ))}
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
