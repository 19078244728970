import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../services/UserService';
import {Box, Button, Typography, useTheme} from "@mui/material";
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import { DataGrid } from '@mui/x-data-grid';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);

    const columns = [
        { field: 'userId', headerName: 'ID' },
        { field: 'email', headerName: 'Email', flex: 1, cellClassName: "name-column--cell" },
        { field: 'name', headerName: 'Last Name', flex: 1 },
        { field: 'vorname', headerName: 'First Name', flex: 1 },
        { field: 'kuerzel', headerName: 'Kürzel', flex: 1 },
        { field: 'anrede', headerName: 'Anrede', flex: 1 },
        { field: 'titel', headerName: 'Titel', flex: 1 },
        { field: 'language', headerName: 'Language', flex: 1 },
        { field: 'excellence', headerName: 'Excellence', flex: 1 },
        { field: 'roleID.roleName', headerName: 'Access Level', flex: 1, renderCell: ({ row: { roleID } }) => {
                const roleName = roleID ? roleID.roleName : "Unknown"; // Default to "Unknown" if role is not found
                return (
                    <Box width="60%" m="0 auto" p="5px" display="flex" justifyContent="center" backgroundColor={
                        roleName === "Admin" ? colors.greenAccent[600] :
                            roleName === "Manager" ? colors.greenAccent[700] :
                                roleName === "Guest" ? colors.greenAccent[800] : // Add more role names as needed
                                    colors.greenAccent[900] // Default color
                    } borderRadius="4px">
                        {roleName === "Admin" && <AdminPanelSettingsOutlinedIcon />}
                        {roleName === "Manager" && <SecurityOutlinedIcon />}
                        {roleName === "Guest" && <LockOpenOutlinedIcon />}
                        {/* Add more icons for additional role names */}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>{roleName}</Typography>
                    </Box>
                );
            }},
        // Add other columns as needed
    ];

    useEffect(() => {
        getAllUsers().then(response => {
            const transformedUsers = response.data.map(user => ({
                id: user.userId, // DataGrid requires a field named 'id'
                ...user
            }));
            setUsers(transformedUsers);
        });
    }, []);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DATABASE" subtitle="User List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                    Download User Data
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[500]
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700]
                    }
                }}
            >
                <DataGrid
                    rows={users}
                    columns={columns}
                />
            </Box>
        </Box>
    );
};

export default Team;
