// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  /*margin: 0;
  padding: 0;*/
  font-family: 'Source Sans 3', sans-serif;
  /*font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2f2f33;
  background-color: #e5ecef;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #141b2d;
}

::-webkit-scrollbar-thumb {
    background: #EE9203;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ee8003;
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;EAKE,YAAY;EACZ,WAAW;EACX;cACY;EACZ,wCAAwC;EACxC;;;;6BAI2B;EAC3B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');\n\nhtml,\nbody,\n#root,\n.app,\n.content {\n  height: 100%;\n  width: 100%;\n  /*margin: 0;\n  padding: 0;*/\n  font-family: 'Source Sans 3', sans-serif;\n  /*font-size: 16px;\n  font-weight: 400;\n  line-height: 1.5;\n  color: #2f2f33;\n  background-color: #e5ecef;*/\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.main-app {\n  display: flex;\n  position: relative;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n}\n::-webkit-scrollbar-track {\n  background: #141b2d;\n}\n\n::-webkit-scrollbar-thumb {\n    background: #EE9203;\n    border-radius: 4px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #ee8003;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
