import React, { useRef, useState, useEffect, useContext } from "react";
import Consultation from "../Assets/consultation.png";
import Strategy from "../Assets/choose-image.png";
import Delivery from "../Assets/ready.png";
import { LanguageContext } from "./LanguageContext";
import translations from "./translations";

const Work = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language]; // Short for "translate"
    const workInfoData = [
        {
            title: t.work_info1,
            text: t.work_info_text1,
            image: Consultation,
        },
        {
            title: t.work_info2,
            text: t.work_info_text2,
            image: Strategy,
        },
        {
            title: t.work_info3,
            text: t.work_info_text3,
            image: Delivery,
        },
    ];

    return (
        <div className="work-section-wrapper" id="process">
            <div className="work-section-top">
                <p className="primary-subheading">{t.work}</p>
                <h1 className="primary-heading">{t.work_heading}</h1>
                <p className="primary-text">{t.work_text}</p>
            </div>
            <div className="work-section-bottom">
                {workInfoData.map((data, index) => (
                    <WorkItem key={index} data={data} />
                ))}
            </div>
        </div>
    );
};

const WorkItem = ({ data }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(); // Use ref for each WorkItem

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 0.5,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref} className={`work-section-info ${isVisible ? 'fade-in-work' : ''}`}>
            <div className="info-boxes-img-container">
                <img src={data.image} alt={data.title} />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
        </div>
    );
};

export default Work;
